import { Col, Row, Form, Input, Select, DatePicker, Typography, Badge } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import {
  genEnterpriseTypeOptions,
  getJointSubjectOption,
  getJointSubjectSim,
} from 'src/pages/management/activeSim/utils';
import ListPackageTable from './ListPackageTable';
import StatusBusinessProfile from './BusinessProfiletStatus';
import dayjs from 'dayjs';
import { dataGet } from 'src/utils';
import { DEFAULT_DATE_FORMAT } from 'src/shared/constants';
import ListPackageEsimTable from './ListPackageEsimTable';
import TextArea from 'antd/es/input/TextArea';
import { cookCode } from 'src/shared/common/userProfile';

export default function SimEnterpriseInfoBox({ accountAttribute, idEnterprise }: any) {
  const [form] = Form.useForm();

  const defaultValue = {
    ...accountAttribute,
    userStreetName: `${accountAttribute.userStreetName}, ${accountAttribute.userPrecinctName}, ${accountAttribute.userDistrictName}, ${accountAttribute.userProvinceName}`,
    foundedPermDate: accountAttribute?.foundedPermDate
      ? dayjs(accountAttribute?.foundedPermDate)
      : null,
  };

  return (
    <BoxWithLabel title='Thông tin doanh nghiệp' classNames='mt-4'>
      <Form
        form={form}
        labelWrap={true}
        initialValues={defaultValue}
        disabled={true}
        labelCol={{ span: 12 }}
        colon={false}
      >
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item label='Mã hồ sơ DN' labelCol={{ span: 8 }} labelAlign={'left'}>
              <Typography.Text>{cookCode(idEnterprise, 'DN')}</Typography.Text>
            </Form.Item>
            <Form.Item
              label='Tên DN'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'enterpriseName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Loại khách hàng'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'enterpriseType'}
            >
              <Select />
            </Form.Item>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  label='Số giấy chứng nhận pháp nhân'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'enterpriseTaxCode'}
                >
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  label='Số giấy phép kinh doanh'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'foundedPermNo'}
                >
                  <Input />
                </Form.Item>
              </Col> */}
            </Row>
            {/* <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item
                  label='Số lượng TB'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name={'enterpriseTotalPhoneNumbers'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Số lượng user'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name={'enterpriseTotalUsers'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={'enterpriseType'}
              label='Loại DN'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
            >
              <Select options={genEnterpriseTypeOptions()} />
            </Form.Item>
            <Form.Item label='Danh sách gói SIM vật lý' labelCol={{ span: 8 }} labelAlign={'left'}>
              <ListPackageTable />
            </Form.Item>
            <Form.Item label='Danh sách gói eSIM' labelCol={{ span: 8 }} labelAlign={'left'}>
              <ListPackageEsimTable />
            </Form.Item> */}
          </Col>
          <Col span={12}>
            {/* <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item
                  label='Cấp DN'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name={'enterpriseLevel'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='LCS account'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name='localShopAccount'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  label='Ngày cấp GPKD'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'foundedPermDate'}
                >
                  <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Nơi cấp GPKD'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'enterpriseFoundingPermPlace'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  label='Địa chỉ'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'userStreetName'}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  label='Tỉnh/TP'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'userProvinceName'}
                >
                  <Select />
                </Form.Item>
              </Col> */}
            </Row>
            {/* <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item
                  label='Quận/Huyện'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'userDistrictName'}
                >
                  <Select />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Phường/Xã'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'userPrecinctName'}
                >
                  <Select />
                </Form.Item>
              </Col>
            </Row> 
            <Form.Item
              label='Distributor ID'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'enterpriseDistributorId'}
            >
              <Select />
            </Form.Item>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item
                  label='Mã giao dịch viên'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name={'enterpriseStaffId'}
                >
                  <Select />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Tên giao dịch viên'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name={'enterpriseStaffName'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item label='Trạng thái HĐ' labelCol={{ span: 10 }} labelAlign={'left'}>
                  {dataGet(accountAttribute, 'activeStatus') === '1' ? (
                    <Badge color={'geekblue'} text={'Active'} />
                  ) : (
                    <Badge color={'red'} text={'InActive'} />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Trạng thái hồ sơ'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  name='status'
                >
                  <StatusBusinessProfile status={dataGet(accountAttribute, 'enterpriseStatus')} />
                </Form.Item>
              </Col>
            </Row>*/}
          </Col>
        </Row>
      </Form>
    </BoxWithLabel>
  );
}
