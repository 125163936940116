import { Form, Radio, RadioChangeEvent, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { getMasterData } from 'src/api';
import ConnectionEnterprise from 'src/components/connection/single/enterprise/ConnectionEnterprise';
import ConnectionIndividual from 'src/components/connection/single/individual/ConnectionIndividual';
import { store } from 'src/redux';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import { connectionTypeToString } from 'src/utils/connection';

const SingleConnection = () => {
  const [currType, setCurrType] = useState<ConnectionType>(ConnectionType.INDIVIDUAL);
  const { masterData } = useAppSelector((state) => state.global);
  const onRadioConnectionChange = ({ target: { value } }: RadioChangeEvent) => {
    setCurrType(value);
  };

  // const fetchMasterDataSubType = async () => {
  //   const res = await getMasterData({ code: "SubType" });
  //   if (res) {
  //     store.dispatch(setGlobalState({
  //       masterData: {
  //         ...masterData,
  //         subType: res
  //       }
  //     }))
  //   }
  // }

  // useEffect(() => {
  //   fetchMasterDataSubType()
  // }, []);

  return (
    <>
      <div className='text-left'>
        <Row>
          <Form.Item label={<span className='font-semibold'>Loại đấu nối</span>}>
            <Radio.Group
              onChange={onRadioConnectionChange}
              name='connectionType'
              defaultValue={ConnectionType.INDIVIDUAL}
            >
              <Radio
                value={ConnectionType.INDIVIDUAL}
                checked={currType === ConnectionType.INDIVIDUAL}
              >
                {connectionTypeToString(ConnectionType.INDIVIDUAL)}
              </Radio>
              <Radio
                value={ConnectionType.ENTERPRISE}
                checked={currType === ConnectionType.ENTERPRISE}
              >
                {connectionTypeToString(ConnectionType.ENTERPRISE)}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
        {currType === ConnectionType.INDIVIDUAL && <ConnectionIndividual />}
        {currType === ConnectionType.ENTERPRISE && <ConnectionEnterprise />}
      </div>
    </>
  );
};

export default SingleConnection;
