import { IDetailReponse } from "src/shared/interface/package_assigned";
import request from "./request"

export async function getMasterData(data: any) {
    const url = "/api/masterdata/get-by-code"
    const res = await request("get", url, data);
    if (res?.isSucceeded) {
        return res?.data;
    }

}