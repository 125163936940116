/* eslint-disable no-unsafe-optional-chaining */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  activeSimBatch,
  checkConnectionImportExcelSerial,
  checkConnectionInputListSerial,
  createOrderConnection,
  deleteOrther,
  getConnectionBatchDetail,
  retryConnection,
  uploadConnectionImportExcelSerial,
} from 'src/api/connection';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import InputCorrect from 'src/components/connection/InputCorrect';
import InputError from 'src/components/connection/InputError';
import ModalConfirmBatchConnect from 'src/components/connection/ModalConfirmBatchConnect';
import ModalConfirmBatchDelete from 'src/components/connection/ModalConfirmBatchDelete';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppSelector } from 'src/redux/hook';
import { setConnectionState } from 'src/redux/slice/connectionSlice';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import { SimType } from 'src/shared/enum/sim.enum';
import {
  ICheckConnectionInputSerialParamsRequest,
  IOverFlowOrderDetailResponse,
  ISimError,
  ISimSuccess,
} from 'src/shared/interface/connection';
import { formatDateTime, parseQueryString } from 'src/utils';
import { connectionTypeToString } from 'src/utils/connection';
import { simTypeToText } from 'src/utils/sim.enum';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const BatchConnectionCreate = () => {
  const [form] = useForm();
  const [detailData] = useState<any>();
  const [radio, setRadio] = useState<number>(1);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConnectModal, setIsOpenConnectModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [fileImport, setFileImport] = useState<any>(null);
  const [simType, setSimType] = useState<number>(SimType.PHYSICAL_SIM);
  const [type, setType] = useState<string>(ConnectionType.INDIVIDUAL);
  const [linkExcelSuccess, setLinkExcelSuccess] = useState<string>('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const { dataSuccess, dataFailed } = useAppSelector((state) => state.connection);
  const [fileNameExcel] = useState<any>();
  const [dataSuccessSended, setDataSuccessSended] = useState<IOverFlowOrderDetailResponse[]>([]);
  const navigate = useNavigate();
  const { id } = parseQueryString(location.search);
  const { refresh, order, openTabs } = useAppSelector((state) => state.global);
  const [statusSim, setStatusSim] = useState<string>(OrderStatusEnum.New);
  const [isImportFile, setIsImportFile] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetailOrder = async () => {
      const res = await getConnectionBatchDetail(id);
      if (res) {
        if (res.fileUrl) {
          setRadio(2);
        }
        setType(res.connectionType);
        setSimType(res?.simType);
        form.setFieldsValue(res);
        const listCheckSuccess: any[] = [];
        const listCheckFail: any[] = [];
        res.overFlowOrderDetailResponses?.forEach((item: any) => {
          if (res.statusString !== OrderStatusEnum.New) {
            listCheckSuccess.push(item);
          } else if (item.statusString === OrderStatusEnum.Failed) {
            if (item.id) {
              const errorItem = {
                code: item.errorCode,
                message: item.errorMessage,
                serial: item.serial,
              };
              listCheckFail.push(errorItem);
            } else {
              listCheckFail.push(item);
            }
          } else {
            listCheckSuccess.push(item);
          }
        });
        setDataSuccessSended(listCheckSuccess);
        dispatch(setConnectionState({ dataSuccess: id ? listCheckSuccess : [] }));
        if (res.statusString !== OrderStatusEnum.New) {
          dispatch(setConnectionState({ dataFailed: id ? listCheckFail : [] }));
        }
        dispatch(setGlobalState({ order: res }));
        setStatusSim(res.statusString ? res.statusString.toString() : '');
      }
    };

    if (id) {
      fetchDetailOrder();
    } else {
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setConnectionState({ dataSuccess: [], dataFailed: [] }));
      form.resetFields();
    }
    return () => {
      setStatusSim(OrderStatusEnum.New);
    };
  }, [id, refresh]);

  //Confirm reload data when condition change
  const confirmReloadData = (callbackOk?: any, callbackCancel?: any) => {
    if (dataSuccess.length || dataFailed.length) {
      Modal.confirm({
        title: 'Xác nhận',
        icon: <ExclamationCircleOutlined />,
        content: 'Bạn có muốn xoá dữ liệu và thực hiện lại?',
        okText: 'Tiếp tục',
        cancelText: 'Huỷ',
        onOk: () => {
          dispatch(
            setConnectionState({
              dataSuccess: [],
              dataFailed: [],
            }),
          );
          typeof callbackOk === 'function' && callbackOk();
        },
        onCancel: () => {
          typeof callbackCancel === 'function' && callbackCancel();
        },
      });
    } else {
      typeof callbackOk === 'function' && callbackOk();
    }
  };

  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const handleToggleEdit = () => {
    setStatusSim(OrderStatusEnum.EDIT);
  };

  const getExcelTemplate = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/api/Provider/export-excel-template?type=1`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExcelTemplate();
  }, []);

  const handleRemoveItemSuccess = (serial: string) => {
    const newDataSuccess = dataSuccess.filter(
      (item: IOverFlowOrderDetailResponse) => item.serial !== serial,
    );
    dispatch(setConnectionState({ dataSuccess: newDataSuccess }));
  };

  const items = [
    {
      label: `${radio === 1 ? 'Nhập đúng' : 'Import đúng'}`,
      key: '1',
      children: (
        <InputCorrect
          data={dataSuccess}
          onRemoveItemSuccess={handleRemoveItemSuccess}
          type={type}
          simType={simType}
          batchStatus={form.getFieldValue('statusString')}
          orderId={form.getFieldValue('id')}
          isEdit={
            statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id)
              ? false
              : true
          }
        />
      ),
    },
    {
      label: `${radio === 1 ? 'Nhập sai' : 'Import sai'}`,
      key: '2',
      children: <InputError dataFailed={dataFailed} />,
    },
  ];

  const propsUpload: UploadProps = {
    beforeUpload: (file) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error(`Định dạng file không đúng`);
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFileImport(null);
      } else {
        file.status = 'done';
        setFileImport(file);
      }
    },
    customRequest: () => handleImport(),
  };

  const handleImport = async () => {
    if (!isImportFile && dataSuccess.length > 0) {
      setOpenModalConfirm(true);
      setIsImportFile(true);
      return;
    }

    if (radio === 2) {
      if (fileImport) {
        const res = await checkConnectionImportExcelSerial({
          distributorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
          excelFile: fileImport.originFileObj ? fileImport.originFileObj : fileImport,
          orderId: id ? id : undefined,
          simType,
        });
        if (res) {
          dispatch(
            setConnectionState({
              dataSuccess: res.simSuccess ?? [],
              dataFailed: res.simError ?? [],
            }),
          );
        }
        setOpenModalConfirm(false);
      } else {
        message.error('Import không thành công. Vui lòng thử lại!');
      }
    }
  };

  const addKeyEditStatus = (arr: any, status: string) => {
    const newArr = arr.map((item: any) => {
      const { serial, subType } = item;
      return {
        subType,
        serial,
        editStatus: status,
        merchantId: JSON.parse(merchantInfo as string)?.merchantId,
      };
    });

    return newArr;
  };

  const handleUploadExcel = async () => {
    if (!fileNameExcel) {
      return;
    }

    try {
      const respon = await uploadConnectionImportExcelSerial({
        file: fileNameExcel,
      });

      setLinkExcelSuccess(respon);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (linkExcelSuccess !== '') {
      handleSubmit();
    }
  }, [linkExcelSuccess]);

  const handleCreateOrder = async () => {
    if (dataSuccess.length === 0) {
      message.error('Vui lòng import số!');
      return;
    }

    setIsLoading(true);
    try {
      let bodyInput;

      if (radio === 1) {
        bodyInput = {
          fromSerial: dataSuccess[0].serial,
          toSerial: dataSuccess[dataSuccess.length - 1].serial,
        };
      }

      const bodyRequest = {
        connectionType: 'Device',
        methodImport: `${radio === 1 ? 'input' : 'import'}`,
        fileName: fileNameExcel?.name,
        countSerial: dataSuccess.length,
        type: 'ActiveLot',
        merchantId: JSON.parse(merchantInfo as string)?.merchantId,
        orderEditStatus: 'Create',
        overFlowOrderDetailRequest: dataSuccess.map((data) => {
          const { serial, kindOfSim, packageType, subType } = data;
          return { subType, serial, kindOfSim, packageType, phoneNumber: data.sim?.isdn ?? data.eSim?.isdn };
        }),
        CreatedBy: JSON.parse(merchantInfo as string)?.merchantName,
        fileUrl: linkExcelSuccess,
        simType,
        ...bodyInput,
      };

      const response = await createOrderConnection(type, bodyRequest);

      if (response) {
        setIsLoading(false);
        message.success('Bạn đã tạo lô thành công !');
        const newPanes = openTabs.filter((pane) => pane.key !== 'connection/batch/list');

        dispatch(
          setGlobalState({
            activeKeyMenu: 'connection/batch/list' + '?id=' + response?.id,
            refresh: !refresh,
          }),
        );

        if (!newPanes.find((tab) => tab.key === 'connection/batch/list' + '?id=' + response?.id)) {
          dispatch(
            setGlobalState({
              openTabs: [
                ...newPanes,
                {
                  label: 'Xem chi tiết lô đấu nối ' + response?.id,
                  children: '',
                  key: 'connection/batch/list' + '?id=' + response?.id,
                  closable: true,
                },
              ],
            }),
          );
        }
        navigate(`/management/connection/batch/list?id=${response?.id}`);
      } else {
        message.error(response?.errorMessage);
      }
    } catch (error: any) {
      setIsLoading(false);
      message.error(error?.response.data.errorMessage);
    }
  };

  const handleEditOrder = async () => {
    if (dataSuccess.length === 0) {
      message.error('Vui lòng import số!');
      return false;
    }

    setIsLoading(true);
    let lastDataSuccess;

    if (dataSuccessSended.length > 0) {
      const dataAdd = addKeyEditStatus(
        dataSuccess.filter((data) => {
          const isAdd = dataSuccessSended.find((dataSuccess: IOverFlowOrderDetailResponse) => {
            return dataSuccess.serial === data.serial;
          });
          return !isAdd;
        }),
        'Add',
      );

      const dataRemove = addKeyEditStatus(
        dataSuccessSended.filter((data: IOverFlowOrderDetailResponse) => {
          const isAdd = dataSuccess.find((dataSuccess) => {
            return dataSuccess.serial === data.serial;
          });
          return !isAdd;
        }),
        'Remove',
      );

      lastDataSuccess = [...dataAdd, ...dataRemove];
    }

    try {
      const bodyRequest = {
        orderEditStatus: 'Edit',
        orderId: id,
        overFlowOrderDetailRequest: lastDataSuccess,
        CreatedBy: JSON.parse(merchantInfo as string)?.merchantName,
        fileUrl: linkExcelSuccess,
        simType,
      };

      const response = await createOrderConnection(type, bodyRequest);

      if (response) {
        setIsLoading(false);
        dispatch(setGlobalState({ refresh: !refresh }));
        setStatusSim(OrderStatusEnum.New);
        message.success('Bạn đã lưu thông tin thành công !');
      }
    } catch (error: any) {
      setIsLoading(false);
      message.error(error?.response.data.errorMessage);
    }
  };

  const handleSubmit = () => {
    if (linkExcelSuccess !== '') {
      handleUploadExcel();
    } else {
      if (statusSim === OrderStatusEnum.New) {
        handleCreateOrder();
      }
      if (statusSim === OrderStatusEnum.EDIT) {
        handleEditOrder();
      }
    }
  };

  const handleAddInput = async (isChangeData = false) => {
    if (!Number(form.getFieldValue('countSerialNumber'))) {
      return;
    }

    if (isImportFile) {
      setOpenModalConfirm(true);
      setIsImportFile(false);
      return;
    }

    const params: ICheckConnectionInputSerialParamsRequest = {
      distributorId: JSON.parse(merchantInfo as string)?.merchantId,
      startSerialNumber: form.getFieldValue('serial'),
      countSerialNumber: Number(form.getFieldValue('countSerialNumber')),
      orderId: id ?? undefined,
      simType: simType || SimType.PHYSICAL_SIM,
    };

    const res = await checkConnectionInputListSerial(type, params);

    if (res) {
      if (isChangeData) {
        setRadio(1);
        dispatch(
          setConnectionState({
            dataSuccess: res.simSuccess ?? [],
            dataFailed: res.simError ?? [],
          }),
        );
        setOpenModalConfirm(false);
      } else {
        if (isImportFile) {
          dispatch(
            setConnectionState({
              dataSuccess: res.simSuccess ?? [],
              dataFailed: res.simError ?? [],
            }),
          );
        } else {
          //Xử lí duplicate
          const newDataSuccess = [...dataSuccess];
          const newDataFailed = [...dataFailed];
          [...dataSuccess, ...res.simSuccess].forEach((item) => {
            //Trường hợp không trùng bất cứ thằng nào.
            if (!dataSuccess.some((obj) => obj.serial === item.serial)) {
              newDataSuccess.push(item);
            }
          });
          [...dataFailed, ...res.simError].forEach((item) => {
            //Trường hợp không trùng bất cứ thằng nào.
            if (!dataFailed.some((obj) => obj.serial === item.serial)) {
              newDataFailed.push(item);
            }
          });
          dispatch(setConnectionState({ dataSuccess: newDataSuccess }));
          dispatch(setConnectionState({ dataFailed: newDataFailed }));
        }
      }
    } else {
      setOpenModalConfirm(false);
    }
  };

  const handleConnect = async () => {
    setIsLoading(true);
    try {
      const response = await activeSimBatch(type, id);
      if (response) {
        message.success('Đấu nối thành công!');
        dispatch(setGlobalState({ refresh: !refresh }));
      }
      setIsLoading(false);
      setIsOpenConnectModal(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleRetry = async () => {
    setIsLoading(true);
    try {
      const response = await retryConnection({
        orderId: id,
        updatedBy: JSON.parse(merchantInfo as string)?.merchantName,
      });

      if (response) {
        let isStatusRetry = false;
        if (response.statusString === OrderStatusEnum.Completed) {
          isStatusRetry = true;
        }
        if (isStatusRetry) {
          message.success('Retry đấu nối thành công');
        } else {
          // message.error('Retry đấu nối thất bại');
          dispatch(setGlobalState({ refresh: !refresh }));
        }
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await deleteOrther(id);
      if (response) {
        message.success('Xoá thành công!');

        const newPanes = openTabs.filter((pane) => pane.key !== 'connection/batch');
        dispatch(
          setGlobalState({
            activeKeyMenu: 'connection/batch',
          }),
        );
        if (!newPanes.find((tab) => tab.key === 'connection/batch')) {
          dispatch(
            setGlobalState({
              openTabs: [
                ...newPanes,
                {
                  label: 'Đấu nối theo lô',
                  children: '',
                  key: 'connection/batch',
                  closable: true,
                },
              ],
            }),
          );
        }
        navigate(`/management/connection/batch`);
      }
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleTypeChange = (value: string) => {
    confirmReloadData(
      () => {
        setType(value);
      },
      () => {
        form.setFieldValue('connectionType', type);
      },
    );
  };

  const onChangeCountSerialNumber = (e: any) => {
    if (e?.target?.value > 1000) {
      message.error('Vượt quá số lượng cho phép!');
      form.setFieldValue('countSerialNumber', null);
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div className='flex flex-col gap-10'>
          <Form
            form={form}
            initialValues={{
              device: 'Doanh nghiệp',
            }}
          >
            <div className='flex'>
              <div className='row-button justify-end mb-[1.5rem]'>
                <Button
                  onClick={handleRetry}
                  disabled={order?.statusString === OrderStatusEnum.Uncompleted ? false : true}
                >
                  Retry
                </Button>
                <Button
                  type='primary'
                  onClick={() => setIsOpenConnectModal(true)}
                  disabled={
                    order?.statusString === OrderStatusEnum.New ||
                      (statusSim === OrderStatusEnum.PAYMENT_FAIL && order?.statusString)
                      ? false
                      : true
                  }
                >
                  Đấu nối
                </Button>
                <Button
                  danger
                  onClick={() => setIsOpenDeleteModal(true)}
                  disabled={statusSim === OrderStatusEnum.New && id ? false : true}
                >
                  Xóa
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={
                    statusSim === OrderStatusEnum.EDIT || (statusSim === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                >
                  Lưu
                </Button>
                <Button
                  disabled={statusSim === OrderStatusEnum.New && id ? false : true}
                  onClick={handleToggleEdit}
                >
                  Sửa
                </Button>
              </div>
            </div>

            <BoxWithLabel title='Thông tin lô'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item labelCol={{ span: 12 }} name={'id'} label='Mã lô'>
                    {form.getFieldValue('id')}
                  </Form.Item>
                  <Form.Item labelCol={{ span: 12 }} label='Loại đấu nối' name='connectionType'>
                    <Select
                      showSearch
                      className='min-w-[150px]'
                      placeholder='Vui lòng chọn'
                      defaultValue={ConnectionType.INDIVIDUAL}
                      onChange={handleTypeChange}
                      disabled={!!form.getFieldValue('quantityTotal')}
                      options={[
                        {
                          value: ConnectionType.ENTERPRISE,
                          label: connectionTypeToString(ConnectionType.ENTERPRISE),
                        },
                        {
                          value: ConnectionType.INDIVIDUAL,
                          label: connectionTypeToString(ConnectionType.INDIVIDUAL),
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                  <Form.Item labelCol={{ span: 12 }} label='Loại SIM' name='simType'>
                    <Select
                      showSearch
                      className='min-w-[150px]'
                      placeholder='Vui lòng chọn'
                      defaultValue={SimType.PHYSICAL_SIM}
                      onChange={(value) => {
                        confirmReloadData(
                          () => {
                            setSimType(value);
                          },
                          () => {
                            form.setFieldValue('simType', simType);
                          },
                        );
                      }}
                      disabled={!!form.getFieldValue('quantityTotal')}
                      options={[
                        {
                          value: SimType.PHYSICAL_SIM,
                          label: simTypeToText(SimType.PHYSICAL_SIM),
                        },
                        {
                          value: SimType.ESIM,
                          label: simTypeToText(SimType.ESIM),
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                  <Form.Item label='Trạng thái' labelCol={{ span: 12 }} name='status'>
                    {form.getFieldValue('statusString')}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Tổng cộng' labelCol={{ span: 12 }}>
                    {form.getFieldValue('quantityTotal')}
                  </Form.Item>
                  <Form.Item label='Thành công' labelCol={{ span: 12 }}>
                    {form.getFieldValue('quantitySuccess')}
                  </Form.Item>
                  <Form.Item label='Thất bại' labelCol={{ span: 12 }}>
                    {form.getFieldValue('quantityFailed')}
                  </Form.Item>
                </Col>
                <Col span={8} className='ml-auto'>
                  <Form.Item label='Người tạo' name='created_by' labelCol={{ span: 13 }}>
                    {form.getFieldValue('createdBy')}
                  </Form.Item>
                  <Form.Item label='Thời gian tạo' name='created_at' labelCol={{ span: 13 }}>
                    {form.getFieldValue('createdAt')
                      ? formatDateTime(form.getFieldValue('createdAt'))
                      : ''}
                  </Form.Item>
                  <Form.Item
                    label='Người cập nhật gần nhất'
                    name='updated_by'
                    labelCol={{ span: 13 }}
                  >
                    {form.getFieldValue('updatedBy')}
                  </Form.Item>
                  <Form.Item
                    label='Thời gian cập nhật gần nhất'
                    name='updated_at'
                    labelCol={{ span: 13 }}
                  >
                    {form.getFieldValue('updatedAt')
                      ? formatDateTime(form.getFieldValue('updatedAt'))
                      : ''}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className='mb-[1rem]'>
                <Col>
                  <Form.Item name='radio'>
                    <Radio.Group
                      onChange={(e: RadioChangeEvent) => {
                        confirmReloadData(
                          () => {
                            setRadio(e.target.value);
                          },
                          () => {
                            form.setFieldValue('radio', radio);
                          },
                        );
                      }}
                      disabled={
                        statusSim === OrderStatusEnum.EDIT ||
                          (statusSim === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                      value={radio}
                      defaultValue={1}
                    >
                      <Radio value={1}>Nhập serial</Radio>
                      <Radio value={2}>Import file</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                {radio === 1 ? (
                  <>
                    <div className='flex gap-2'>
                      <Form.Item
                        label='Từ số serial'
                        name='serial'
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số serial',
                          },
                          {
                            min: 16,
                            message: 'Vui lòng nhập đủ số serial',
                          },
                        ]}
                      >
                        <Input
                          type='number'
                          maxLength={20}
                          disabled={
                            statusSim === OrderStatusEnum.EDIT ||
                              (statusSim === OrderStatusEnum.New && !id)
                              ? false
                              : true
                          }
                          style={{ minWidth: '200px' }}
                          onChange={(e) => {
                            if (e.target.value.length <= 20) {
                              form.setFieldValue('serial', e.target.value);
                            } else {
                              form.setFieldValue('serial', e.target.value.substring(0, 20));
                            }
                            e.preventDefault();
                          }}
                          onKeyDown={(evt) => {
                            if (!evt.ctrlKey && !evt.metaKey) {
                              if (
                                evt.key === 'e' ||
                                evt.key === '.' ||
                                evt.key === '+' ||
                                evt.key === '-' ||
                                (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                                Number(evt.key) > 9 ||
                                Number(evt.key) < 0
                              ) {
                                evt.preventDefault();
                              }
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Số lượng'
                        name='countSerialNumber'
                        rules={[
                          { required: true, message: 'Vui lòng nhập số lượng' },
                          { max: 1000, message: 'Vượt quá số lượng cho phép.' },
                        ]}
                      >
                        <Input
                          type={'number'}
                          onKeyDown={(evt) => {
                            if (!evt.ctrlKey && !evt.metaKey) {
                              if (
                                evt.key === 'e' ||
                                evt.key === '+' ||
                                evt.key === '.' ||
                                evt.key === '-' ||
                                (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                                Number(evt.key) > 9 ||
                                Number(evt.key) < 0
                              ) {
                                evt.preventDefault();
                              }
                            }
                          }}
                          maxLength={5}
                          disabled={
                            statusSim === OrderStatusEnum.EDIT ||
                              (statusSim === OrderStatusEnum.New && !id)
                              ? false
                              : true
                          }
                          onChange={(value) => onChangeCountSerialNumber(value)}
                        />
                      </Form.Item>
                      <Button
                        onClick={() => {
                          form
                            .validateFields(['serial', 'countSerialNumber'])
                            .then(() => {
                              if (radio === 1 && !fileImport) {
                                handleAddInput();
                              } else {
                                setOpenModalConfirm(true);
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                        disabled={
                          statusSim === OrderStatusEnum.EDIT ||
                            (statusSim === OrderStatusEnum.New && !id)
                            ? false
                            : true
                        }
                        type='primary'
                      >
                        Thêm
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {detailData?.fileUrl && (
                      <Col>
                        <a href={detailData?.fileUrl} download>
                          <Button type='link'>{detailData?.fileName || 'Excel file'}</Button>
                        </a>
                      </Col>
                    )}
                    <Col>
                      <div className={'mb-[1rem]'}>
                        <Upload
                          {...propsUpload}
                          fileList={detailData?.fileUrl ? [] : fileImport ? [fileImport] : []}
                          maxCount={1}
                          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          disabled={
                            statusSim === OrderStatusEnum.EDIT ||
                              (statusSim === OrderStatusEnum.New && !id)
                              ? false
                              : true
                          }
                        >
                          <Button
                            disabled={
                              statusSim === OrderStatusEnum.EDIT ||
                                (statusSim === OrderStatusEnum.New && !id)
                                ? false
                                : true
                            }
                          >
                            Chọn file
                          </Button>
                        </Upload>
                      </div>
                    </Col>
                    <Col>
                      <Button>
                        <a
                          href={`${process.env.REACT_APP_API_URL}/api/Provider/export-excel-template?type=1`}
                        >
                          Export template
                        </a>
                      </Button>
                    </Col>
                    {/* <Col>
                  <Button type='primary'>Import</Button>
                </Col> */}
                  </>
                )}
              </Row>
            </BoxWithLabel>
          </Form>
          <BoxWithLabel title='Danh sách SIM đấu nối'>
            <Tabs type='card' items={items} />
          </BoxWithLabel>
        </div>
        <ModalConfirmBatchDelete
          isOpen={isOpenDeleteModal}
          handleCancel={() => setIsOpenDeleteModal(false)}
          handleDelete={handleDelete}
          isLoading={isLoading}
        />
        <ModalConfirmBatchConnect
          isOpen={isOpenConnectModal}
          handleCancel={() => setIsOpenConnectModal(false)}
          handleConnect={handleConnect}
          dataSuccess={dataSuccess}
          isLoading={isLoading}
        />
        <ModalConfirm
          open={openModalConfirm}
          content={
            <div className='pb-0 p-8'>
              <div className='font-black text-3xl mb-4'>Bạn chắc chưa?</div>
              <p>Sau khi thêm sẽ xóa dữ liệu trước đó</p>
              <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
                <Button
                  onClick={() => {
                    // Import serial
                    if (radio === 1) {
                      handleAddInput(true);
                    } else {
                      handleImport();
                    }
                  }}
                  className='h-full w-full'
                >
                  XÁC NHẬN
                </Button>
                <Button
                  className='h-full w-full'
                  onClick={() => {
                    setIsImportFile(!isImportFile);
                    setOpenModalConfirm(false);
                  }}
                >
                  THOÁT
                </Button>
              </div>
            </div>
          }
        />
      </Spin>
    </>
  );
};

export default BatchConnectionCreate;
// function setStatusSim(arg0: any) {
//   throw new Error('Function not implemented.');
// }
