import { Button, Col, DatePicker, Form, message, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn } from 'src/@types/search-params';
import { exportInfoActiveSim, getInfoActiveSim } from 'src/api/activeSim';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { OpenNewTab } from 'src/shared/common/openNewTab';
import { SimType } from 'src/shared/enum/sim.enum';
import {
  dataGet,
  exportExcelPost,
  formatPhoneNumber,
  nonAccentVietnamese,
  parseQueryString,
} from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import {
  getJointSubjectSim,
  getJointSubjectOption,
  getSimStatusBasic,
  getSimStatusOption,
  getUcmCardTypeID,
  listSubscriptionStatusOption,
} from '../../utils';
import { useAllPackage } from '../hooks';
const { Option } = Select;

interface FormData {
  simType?: number | string;
  statusSim?: number | string;
  dateRange?: [Moment, Moment];
  search?: string;
  goodCode?: string;
  custType?: string;
  statusIsdn?: string | number;
}

const initFilters = {
  offset: 0,
  limit: 10,
};

const ActiveSim = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { data: allPackageData } = useAllPackage();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataSource, setDataSource] = useState();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();

  const fetchActiveSimInfoData = async () => {
    try {
      const { custType, statusIsdn } = parseQueryString(location.search);
      if (!custType) {
        message.error('Vui lòng chọn đối tượng!');
        setDataSource(undefined);
      } else if (!statusIsdn) {
        message.error('Vui lòng chọn trạng thái thuê bao!');
        setDataSource(undefined);
      } else {
        const res = await getInfoActiveSim(location.search);
        setDataSource(res);
      }
    } catch (error) {
      setDataSource(undefined);
    }
  };

  useEffect(() => {
    const { fromDate, toDate } = parseQueryString(location.search);
    if (fromDate && toDate) {
      fetchActiveSimInfoData();
    } else {
      setTimeout(() => {
        setDataSource(undefined);
      }, 1500);
    }
  }, [location.search]);

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', align: 'center', isHideSort: true, width: 50 },
    {
      name: 'SĐT',
      fieldName: 'isdn',
      align: 'center',
      width: 150,
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {record.isdn ? '0' + formatPhoneNumber(record.isdn) : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Serial',
      fieldName: 'serial',
      align: 'center',
      width: 150,
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            {record?.serial}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Loại SIM',
      fieldName: 'simType',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {simTypeToText(Number(record.simType) + 1)}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày gán gói ban đầu',
      fieldName: 'kittingDate',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'kittingDate') ? dataGet(record, 'kittingDate').split(' ')[0] : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Gói cước ban đầu',
      fieldName: 'goodCode',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{dataGet(record, 'goodCode')}</Typography.Text>;
      },
    },
    {
      name: 'Ngày kích hoạt',
      fieldName: 'activeDate',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'activeDate') ? dataGet(record, 'activeDate').split(' ')[0] : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Mã hồ sơ DN',
      fieldName: 'companyCode',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Tên hồ sơ DN',
      fieldName: 'companyName',
      isHideSort: true,
      align: 'center',
      width: 150,
    },
    {
      name: 'Đối tượng',
      fieldName: 'custType',
      isHideSort: true,
      align: 'center',
      width: 150,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'custType') ? getJointSubjectSim(dataGet(record, 'custType')) : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Tên thuê bao',
      fieldName: 'subName',
      isHideSort: true,
      align: 'center',
      width: 200,
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'idType',
      isHideSort: true,
      width: 150,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {getUcmCardTypeID(Number(dataGet(record, 'idType')))}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'idNo',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Trạng thái TB',
      fieldName: 'status',
      isHideSort: true,
      width: 150,
      align: 'center',
      renderSpecialCard: (record) => {
        if (Number(record.status)) {
          return <Typography.Text className='Text'>{'Đang hoạt động'}</Typography.Text>;
        } else {
          return <Typography.Text className='Text'>{'Đã huỷ'}</Typography.Text>;
        }
      },
    },
    {
      name: 'Trạng thái SIM',
      fieldName: 'activate',
      isHideSort: true,
      width: 180,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>{getSimStatusBasic(record.activate)}</Typography.Text>
        );
      },
    },
    {
      name: 'Thao tác',
      fieldName: '',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            Chi tiết
          </Typography.Text>
        );
      },
    },
  ];

  const handleSearch = (formValues: FormData) => {
    const currentQueryStr = parseQueryString(location.search);
    delete currentQueryStr.pageIndex;
    delete currentQueryStr.pageSize;
    const { simType, statusSim, dateRange, goodCode, custType, statusIsdn } = formValues;
    const searchParams = queryString.stringify({
      statusSim: statusSim === '-1' ? undefined : statusSim,
      simType: simType === '-1' ? undefined : simType,
      custType: custType === '-1' ? undefined : custType,
      statusIsdn: statusIsdn === '-1' ? undefined : statusIsdn,
      goodCode: goodCode === '-1' ? undefined : goodCode,
      pageIndex: 1,
      pageSize: dataGet(currentQueryStr, 'pageSize', 10),
      fromDate: dateRange ? dateRange[0].format('YYYY-MM-DD') : undefined,
      toDate: dateRange ? dateRange[1].format('YYYY-MM-DD') : undefined,
    });
    setSearchParams(searchParams);
  };

  const defaultValues = {
    goodCode: '-1',
    dateRange: [dayjs(), dayjs()],
    custType: '',
    simType: '',
    statusSim: '-1',
    statusIsdn: '',
  };

  const handleDetailActiveSim = (data: any) => {
    OpenNewTab(
      `active-sim/detail?serial=${data?.serial}&phone=${data?.isdn}`,
      `Chi tiết thuê bao ${data?.isdn ? '0' + data?.isdn : ''}`,
    );
  };

  const handleDeleteFilter = () => {
    form.resetFields();
    setSearchParams({});
  };

  const handleExport = () => {
    const searchParams = parseQueryString(location.search);
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_thue_bao_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const url = '/api/user-profile/export-info-active-sim';
    exportExcelPost(url, searchParams)
      .then((res) => {
        message.success('Xuất file excel thành công');
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={handleSearch}
          initialValues={defaultValues}
          layout='inline'
          className='gap-2'
        >
          <Row gutter={[5, 15]} className={'w-full'}>
            {/* <Col xs={7}>
              <Form.Item name={'search'}>
                <Input
                  allowClear
                  prefix={<SearchOutlined />}
                  placeholder='Tìm kiếm theo SĐT, Sim serial, Họ và tên, Số giấy tờ'
                ></Input>
              </Form.Item>
            </Col> */}
            <Form.Item
              name='dateRange'
              rules={[{ required: true, message: 'Vui lòng chọn ngày kích hoạt' }]}
              label={<div>Ngày kích hoạt</div>}
            >
              <DatePicker.RangePicker
                placeholder={['từ', 'đến']}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                className='min-w-[150px]'
                allowClear={false}
                placement={'bottomRight'}
                disabledDate={(current) => {
                  return current.isAfter(dayjs());
                }}
              />
            </Form.Item>
            <Form.Item
              name='custType'
              rules={[{ required: true, message: 'Vui lòng chọn đối tượng' }]}
              label={<div>Đối tượng</div>}
            >
              <Select
                showSearch
                className='min-w-[150px]'
                filterOption={(input, option) => {
                  return nonAccentVietnamese(
                    (option!.label as unknown as string).toString()?.toLowerCase(),
                  ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                }}
                options={getJointSubjectOption()}
              ></Select>
            </Form.Item>
            <Form.Item name='statusSim' label='Trạng thái SIM'>
              <Select
                showSearch
                className='min-w-[150px]'
                filterOption={(input, option) => {
                  return nonAccentVietnamese(
                    (option!.label as unknown as string).toString()?.toLowerCase(),
                  ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                }}
                options={[{ label: 'Tất cả', value: '-1' }, ...getSimStatusOption()]}
              ></Select>
            </Form.Item>
            <Form.Item
              name='simType'
              label='Loại SIM'
              rules={[{ required: true, message: 'Vui lòng chọn loại sim' }]}
            >
              <Select
                showSearch
                className='min-w-[150px]'
                filterOption={(input, option) => {
                  return nonAccentVietnamese(
                    (simTypeToText(option!.value) as unknown as string).toString()?.toLowerCase(),
                  ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                }}
              >
                <Option key={SimType.PHYSICAL_SIM} value={SimType.PHYSICAL_SIM}>
                  {simTypeToText(SimType.PHYSICAL_SIM)}
                </Option>
                <Option key={SimType.ESIM} value={SimType.ESIM}>
                  {simTypeToText(SimType.ESIM)}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item name='goodCode' label='Gói cước ban đầu'>
              <Select
                showSearch
                className='min-w-[150px]'
                filterOption={(input, option) => {
                  return nonAccentVietnamese(
                    (option!.value as unknown as string).toString()?.toLowerCase(),
                  ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                }}
              >
                <Option key={'-1'} value={'-1'}>
                  {'Tất cả'}
                </Option>
                {allPackageData?.map((item: any) => (
                  <Option key={item + 1} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='statusIsdn'
              rules={[{ required: true, message: 'Vui lòng chọn trạng thái thuê bao' }]}
              label={<div>Trạng thái thuê bao</div>}
            >
              <Select
                showSearch
                className='min-w-[150px]'
                filterOption={(input, option) => {
                  return nonAccentVietnamese(
                    (option!.label as unknown as string).toString()?.toLowerCase(),
                  ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                }}
                options={listSubscriptionStatusOption()}
              ></Select>
            </Form.Item>
          </Row>
          <div className={'row-button justify-end flex-wrap-reverse mt-3'}>
            <Button onClick={() => handleDeleteFilter()} className={'m-1'}>
              Xóa tìm kiếm
            </Button>
            <Button type={'primary'} htmlType='submit' className={'m-1'}>
              Tìm kiếm
            </Button>
            <Button
              onClick={() => {
                const { custType, statusIsdn, fromDate, toDate } = parseQueryString(
                  location.search,
                );
                if (custType && statusIsdn && fromDate && toDate) {
                  handleExport();
                } else {
                  message.error('Vui lòng chọn đầy đủ thông tin tìm kiếm!');
                }
              }}
              className={'m-1'}
            >
              Export
            </Button>
          </div>
        </Form>
      </div>
      <div className='mt-4'>
        <TableContent
          bordered
          // loading={isLoading}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          totalRecord={dataGet(dataSource, 'rowCount', 0)}
          dataSource={dataGet(dataSource, 'results', [])}
          columns={columns}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
            });
          }}
        />
      </div>

    </>
  );
};

export default ActiveSim;
