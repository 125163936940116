import SimInfoBox from './sim_info/SimInfoBox';
import SimEnterpriseInfoBox from './sim_info/SimEnterpriseInfoBox';
import SimAgentInfoBox from './sim_info/SimAgentInfoBox';
import SimUserInfoBox from './sim_info/SimUserInfoBox';
import AuthorizedInfoBox from './sim_info/AuthorizedInfoBox';
import SimImageProfileBox from './sim_info/SimImageProfileBox';
import TraderProfileBox from './sim_info/TraderProfileBox';
import { dataGet } from 'src/utils';

export default function SimInfoTab({ simDetail }: any) {
  return (
    <div className={'sim-info-tab'}>
      <SimInfoBox simDetail={simDetail} />
      <SimEnterpriseInfoBox
        accountAttribute={dataGet(simDetail, 'enterpriseProfile.accountAttribute')}
        idEnterprise={dataGet(simDetail, 'enterpriseProfile.id')}
      />
      <SimAgentInfoBox
        accountAttribute={dataGet(simDetail, 'enterpriseProfile.accountAttribute')}
      />
      {/* <AuthorizedInfoBox
        accountAttribute={dataGet(simDetail, 'enterpriseProfile.accountAttribute')}
      />
      <TraderProfileBox
        accountAttribute={dataGet(simDetail, 'enterpriseProfile.accountAttribute')}
      />
      <SimImageProfileBox
        accountAttribute={dataGet(simDetail, 'enterpriseProfile.accountAttribute')}
      /> */}
      <SimUserInfoBox simDetail={simDetail} />
    </div>
  );
}
