import request from '../request';
import {
  DetailHistorySubcriber,
  HistorySubcriber,
  IParamsSearchHistorySubcribe,
  TransactionHistoryParams,
} from 'src/@types/search-params';
import axios from 'axios';
import qs from 'qs';

export const getInfoActiveSim = async (queryParam: string) => {
  const res = await request('get', `/api/user-profile/get-info-active-sim` + queryParam);

  if (res.isSucceeded) {
    return res.data;
  }
};

export const getInfoActiveSimDetail = async (serial: string, phone: string) => {
  const res = await request(
    'get',
    `/api/user-profile/get-detail-info-active-sim?serial=${serial}&phone=${phone}`,
  );

  if (res.isSucceeded) {
    return res.data;
  }
};

export const getSimHistoryChange = async (params: IParamsSearchHistorySubcribe) => {
  const res = await request('get', `/api/user-profile/search-history-subcriber`, params);

  if (res.isSucceeded) {
    return res.data;
  }
};

export const getAllPackage = async () => {
  const res = await axios.get(`${process.env.REACT_APP_PORTAL_MLC}/public/meta`);

  if (res.data) {
    return res.data;
  }
};

export const getAllPackageByLCS = async () => {
  const res = await request('get', `/api/Provider/register-package/data-package/0`);

  if (res.data) {
    return res.data;
  }
};

export const exportInfoActiveSim = async (searchParams = {}) => {
  const res = await request('post', `/api/user-profile/export-info-active-sim`, searchParams, {
    paramsSerializer: {
      serialize: (searchParams) => qs.stringify(searchParams, { arrayFormat: 'repeat' }),
    },
    responseType: 'blob',
  });

  if (res.isSucceeded) {
    return res;
  }
};
export const getEnumActionTypeManagement = async () => {
  const res = await request('get', `/api/user-profile/action-type-management`);

  if (res.isSucceeded) {
    return res.data;
  }
};
export const getSearchDetailHistorySubcriber = async (data: HistorySubcriber) => {
  const res = await request('post', `/api/user-profile/search-detail-history-subcriber`, data);

  if (res.isSucceeded) {
    return res.data;
  }
};
export const getSearchActiveSim = async (queryParam: string) => {
  const res = await request('get', `/api/user-profile/search-info-active-sim` + queryParam);

  if (res.isSucceeded) {
    return res.data;
  }
};
