import { ConnectionType } from 'src/shared/enum/connection.enum';
import {
  ICheckCheckPickedSerialSimRequest,
  ICheckConnectionInputListSerial,
  ICheckConnectionInputSerialParamsRequest,
  ICheckImportConnectionExcelParamsRequest,
  ICheckUploadConnectionImportExcelSerial,
} from 'src/shared/interface/connection';
import request from '../request';

export const checkPickedSerialSim = async (params: ICheckCheckPickedSerialSimRequest) => {
  const url = '/api/Provider/check-picked-serial-sim';
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkPickedSerialSimUserProfile = async (
  params: ICheckCheckPickedSerialSimRequest,
) => {
  const url = '/api/user-profile/check-picked-serial-sim';
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const reserveNumber = async (currNumber?: string, pool?: number) => {
  const url = `/api/Provider/reserve-number-hkdl-local?phoneNumber=${currNumber}&pool=${pool}`;
  const res = await request('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const activeSimSingle = async (type: string, params: any) => {
  const url = `/api/${
    type === ConnectionType.ENTERPRISE ? 'Provider' : 'user-profile'
  }/active-sim-by-enterprise-single`;

  try {
    const res = await request('post', url, params);
    return res;
  } catch (error: any) {
    return false;
  }
};

export const repickedNumber = async (currNumber?: string, pool?: number) => {
  const url = `/api/Provider/repicked-number-hkdl-local?phoneNumber=${currNumber}&pool=${pool}`;
  const res = await request('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const uploadConnectionImportExcelSerial = async (
  params: ICheckUploadConnectionImportExcelSerial,
) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });

  const url = '/api/filestore/uploadFileExcel';
  const res = await request('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkConnectionImportExcelSerial = async (
  params: ICheckImportConnectionExcelParamsRequest,
) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });
  const url = '/api/Provider/check-import-list-serial-sim';
  const res = await request<ICheckConnectionInputListSerial>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkConnectionInputListSerial = async (
  type: string,
  params: ICheckConnectionInputSerialParamsRequest,
) => {
  const url = `/api/${
    type === ConnectionType.ENTERPRISE ? 'Provider' : 'user-profile'
  }/check-input-list-serial-sim`;
  const res = await request<ICheckConnectionInputListSerial>('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const createOrderConnection = async (type: string, params: any) => {
  const url = `/api/${
    type === ConnectionType.ENTERPRISE ? 'Provider' : 'user-profile'
  }/create-order`;
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getAllPhoneNumber = async (params: any) => {
  const url = '/api/Provider/get-all-phoneNumber-hkdl-local';
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getPackageTypes = async () => {
  const url = '/api/Provider/package-type';
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getConnectionBatchList = async (params: any) => {
  const url = '/api/Provider/get-all-activelot';
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getConnectionBatchDetail = async (id: any) => {
  const url = `/api/Provider/get-detail-oderder-activelot?orderId=${id}`;
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const deleteOrther = async (id: string) => {
  const url = `/api/Provider/delete-order?orderId=${id}`;
  const res = await request<ICheckConnectionInputListSerial>('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const activeSimBatch = async (type: string, id: string) => {
  const url = `/api/${
    type === ConnectionType.ENTERPRISE ? 'Provider' : 'user-profile'
  }/active-sim-by-enterprise-lot/${id}`;
  const res = await request('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const retryConnection = async (params: any) => {
  const url = `/api/Provider/retry-order`;
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkStatusSerial = async (serial: string) => {
  const url = `/api/Provider/check-active/serial?serial=${serial}`;
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getConnectionHistory = async (params: any) => {
  const url = `/api/Provider/get-history-active`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getConnectionHistoryDetail = async (params: any) => {
  const url = `/api/Provider/get-detail-history-active`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getAddress = async (params: any) => {
  const url = `/api/location`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getUserProfiles = async () => {
  const url = `/api/user-profile/active/get-all?isActiveSimValid=true`;
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getIssuePlace = async () => {
  const url = `/api/issue-place/get-all`;
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const getNationality = async () => {
  const url = `/api/nationality/get-all`;
  const res = await request('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const uploadImages = async (file: any) => {
  const url = `/uploadImages`;
  const res = await request('post', url, file);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const historyRetry = async (type: string, params: any) => {
  const url = `/api/${
    type === ConnectionType.ENTERPRISE ? 'Provider' : 'user-profile'
  }/retry-detail-history-active`;

  try {
    const res = await request('post', url, params);
    console.log('errorerrorerror ressss', res);
    return res;
  } catch (error: any) {
    console.log('errorerrorerror 1', error);
    if (
      error?.message === 'timeout of 60000ms exceeded' ||
      error?.message === 'Request failed with status code 400'
    ) {
      console.log('timeout');
      return false;
    } else {
      console.log('respon error');
      return error;
    }
  }
};

export const exportHistory = async (params: any) => {
  const url = `/api/Provider/history/export`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
