import { Button, message } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { ConnectionType } from 'src/shared/enum/connection.enum';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/connection';
import { formatDate, formatPhoneNumberV2, handleDownloadImage, renderSubType } from 'src/utils';
import reducer from '../../redux/reducer';
import { OrderStatusEnum } from '../../shared/enum/package_assigned';
import { SimType } from '../../shared/enum/sim.enum';

type Props = {
  data: IOverFlowOrderDetailResponse[];
  onRemoveItemSuccess: (serial: string) => void;
  isEdit: boolean;
  type: string;
  simType: number;
  batchStatus?: string;
  orderId?: string;
};

function InputCorrect(props: Props) {
  const { data, onRemoveItemSuccess, isEdit, type, batchStatus, orderId } = props;
  const dispatch = useDispatch();
  const { openTabs, masterData, listPackageType } = useAppSelector((state) => state.global);
  const navigate = useNavigate();

  const handleDetailOrder = (order: any) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: `connection/history/list?id=${order.serial}&orderId=${orderId}`,
      }),
    );
    if (
      !openTabs.find(
        (tab) => tab.key === `connection/history/list?id=${order.serial}&orderId=${orderId}`,
      )
    ) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem lịch sử đấu nối ' + order.serial,
              children: '',
              key: `connection/history/list?id=${order.serial}&orderId=${orderId}`,
              closable: true,
            },
          ],
        }),
      );
    }
    navigate(`/management/connection/history/list?id=${order.serial}&orderId=${order.orderId}`);
  };

  const handleDownloadQRCode = async (record: any) => {
    if (record?.linkQrText) {
      fetch(record?.linkQrText, { mode: 'cors' }).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${record?.serial + '_' + dayjs()}`;
          alink.click();
        });
      });
    } else {
      message.error('Đã xảy ra lỗi!');
    }
  };

  const columnsIndividual: IListColumn[] =
    type === ConnectionType.INDIVIDUAL
      ? [
        {
          name: 'ID hồ sơ NSD',
          fieldName: 'userProfile',
          isHideSort: true,
          renderSpecialCard: (data) => {
            return <span>{data?.userProfile?.id || ''}</span>;
          },
        },
        {
          name: 'Số giấy tờ',
          fieldName: 'userProfile',
          isHideSort: true,
          renderSpecialCard: (data) => {
            return <span>{data?.userProfile?.idNumber || ''}</span>;
          },
        },
        {
          name: 'Họ tên',
          fieldName: 'userProfile',
          isHideSort: true,
          renderSpecialCard: (data) => {
            return <span>{data?.userProfile?.userName || ''}</span>;
          },
        },
        {
          name: 'Ngày sinh',
          fieldName: 'userProfile',
          isHideSort: true,
          renderSpecialCard: (data) => {
            return (
              <span>{data?.userProfile?.birthDay && formatDate(data?.userProfile.birthDay)}</span>
            );
          },
        },
      ]
      : [];

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    { name: 'SIM serial', fieldName: 'serial', isHideSort: true },
    {
      name: 'Mã gói',
      fieldName: 'packageCode',
      renderSpecialCard: (data) => {
        return <span>{data?.sim?.mbf_code ?? data?.eSim?.packageCode ?? data.mbfCode}</span>;
      },
      isHideSort: true,
    },
    {
      name: 'Số điện thoại',
      fieldName: 'phoneNumber',
      isHideSort: true,
      renderSpecialCard: (data) => {
        return (
          <span>
            {formatPhoneNumberV2(data?.sim?.isdn ?? data?.eSim?.isdn ?? data.phoneNumber)}
          </span>
        );
      },
    },
    {
      name: 'Loại thuê bao',
      fieldName: 'subType',
      isHideSort: true,
      renderSpecialCard: (data) => {
        return (
          <span>
            {renderSubType(masterData, data?.subType)}
          </span>
        );
      },
    },
    ...columnsIndividual,
    {
      name: 'Trạng thái',
      fieldName: 'statusString',
      renderSpecialCard: (data) => {
        return <span>{data?.statusString}</span>;
      },
      isHideSort: true,
    },
    {
      name: 'Mã lỗi',
      fieldName: 'errorCode',
      isHideSort: true,
    },
    {
      name: 'Mô tả lỗi',
      fieldName: 'errorMessage',
      isHideSort: true,
    },
    {
      name: 'Thao tác',
      fieldName: 'serial',
      renderSpecialCard: (data) => {
        if (batchStatus === 'Completed' || batchStatus === 'Uncompleted') {
          return (
            <button
              className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
              onClick={() => handleDetailOrder(data)}
            >
              Chi tiết
            </button>
          );
        } else {
          return (
            <Button
              type='link'
              disabled={isEdit}
              onClick={() => onRemoveItemSuccess(data?.serial)}
              className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
            >
              Xóa
            </Button>
          );
        }
      },
      isHideSort: true,
    },
  ];

  if (props?.simType === SimType.ESIM) {
    columns.push({
      name: 'Mã QR',
      fieldName: 'linkQrText',
      isHideSort: true,
      renderSpecialCard: (data) => {
        return (
          data?.statusString === 'Success' && (
            <button
              className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
              onClick={() => handleDownloadImage(data?.linkQrText)}
            >
              Tải về
            </button>
          )
        );
      },
    });
  }

  return (
    <TableContent
      bordered
      scroll={{ x: 'max-content' }}
      locale={{ emptyText: 'Không tìm thấy kết quả' }}
      dataSource={data}
      columns={columns}
      isHidePaging={true}
    />
  );
}

export default InputCorrect;
