import { Button, Col, DatePicker, Form, Row, Select, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HistorySubcriber, IParamsSearchHistorySubcribe } from 'src/@types/search-params';
import { getEnumActionTypeManagement, getSimHistoryChange } from 'src/api/activeSim';
import { ValueCode } from 'src/shared/interface/user_profile';
import { dataGet, nonAccentVietnamese } from 'src/utils';
import ModalDetailHistory from './modal/ModalDetailHistory';

interface FormData {
  dateRange?: [Moment, Moment];
  ActionCode?: string;
}
export default function SimHistoryChangeTab({ simDetail }: any) {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const [history, setHistory] = useState<HistorySubcriber[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enumActionType, setEnumActionType] = useState<ValueCode[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [detailHistory, setDetailHistory] = useState<HistorySubcriber>();

  useEffect(() => {
    const fetchListEnumActionType = async () => {
      const listEnum: any = await getEnumActionTypeManagement();
      if (listEnum) {
        const mappingData = listEnum.map((item: { name: any; actionCode: any }) => {
          return {
            label: item.name,
            value: item.actionCode,
          };
        });
        setEnumActionType(mappingData);
      }
    };
    fetchListEnumActionType();
  }, []);

  const fetchSimHistoryChangeData = async (formValues: FormData) => {
    setIsLoading(true);
    try {
      const { dateRange, ActionCode } = formValues;
      const query: IParamsSearchHistorySubcribe = {
        subId: simDetail.subcriberInfoBss.subscriberId,
        FromDate: dateRange ? dateRange[0].format('YYYY-MM-DD') : '',
        ToDate: dateRange ? dateRange[1].format('YYYY-MM-DD') : '',
        ActionCode,
      };
      const res = await getSimHistoryChange(query);
      console.log(res);

      setHistory(res?.results);
    } catch (error) {
      setHistory([]);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'STT',
      width: 50,
      render: (text: string, record: any, index: number) => {
        return index + 1;
      },
    },
    {
      title: 'Thời gian tác động',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 250,
      render: (text: string) => {
        return <Typography.Text>{text ? text : ''}</Typography.Text>;
      },
    },
    {
      title: 'Kênh',
      dataIndex: 'channel',
      width: 100,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Cửa hàng',
      dataIndex: 'shop',
      width: 150,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Nhân viên',
      dataIndex: 'employee',
      width: 150,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Nhân viên phát triển',
      dataIndex: 'createUser',
      width: 280,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Lý do',
      width: 400,
      dataIndex: 'reasonName',
      render: (_: any, record: any) => {
        return (
          <Space direction={'vertical'} size={'small'}>
            {dataGet(record, 'reasonName', '')
              .split(';')
              ?.map((item: string, index: number) => (
                <Typography.Text key={index}>{item}</Typography.Text>
              ))}
          </Space>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: 120,
      render: (_: any, record: any) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            disabled={record.orderIdCreate === record.orderIdUpdate ? true : false}
            onClick={() => {
              if (record.orderIdCreate !== record.orderIdUpdate) {
                setOpened(true);
                setDetailHistory(record);
              }
            }}
          >
            Xem chi tiết
          </Typography.Text>
        );
      },
    },
  ];

  const defaultValues = {
    dateRange: [dayjs(), dayjs()],
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={fetchSimHistoryChangeData}
          initialValues={defaultValues}
          layout='inline'
          className='gap-2'
        >
          <Row gutter={[5, 15]} className={'w-full'}>
            <Col xs={12}>
              <Form.Item
                name='dateRange'
                label={
                  <div>
                    Thời gian <span className='text-red-600'>*</span>
                  </div>
                }
              >
                <DatePicker.RangePicker
                  placeholder={['từ', 'đến']}
                  format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                  className={'w-full'}
                  allowClear={false}
                  placement={'bottomRight'}
                  disabledDate={(current) => {
                    return current.isAfter(dayjs());
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                name='ActionCode'
                label={
                  <div>
                    Tác động <span className='text-red-600'>*</span>
                  </div>
                }
              >
                <Select
                  showSearch
                  allowClear
                  options={enumActionType}
                  filterOption={(input, option) => {
                    return nonAccentVietnamese(
                      (option!.label as unknown as string).toString()?.toLowerCase(),
                    ).includes(nonAccentVietnamese(input?.toLowerCase()?.trim()));
                  }}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <div className={'row-button justify-end flex-wrap-reverse mt-3'}>
            <Button
              onClick={() => {
                if (form.getFieldValue('dateRange')) {
                  fetchSimHistoryChangeData(form.getFieldsValue());
                }
              }}
              className={'m-1'}
            >
              Làm mới
            </Button>
            <Button type={'primary'} htmlType='submit' className={'m-1'}>
              Tìm kiếm
            </Button>
          </div>
        </Form>
      </div>
      <div className={'sim-history-change-tab'}>
        <Table
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={history}
          rowKey={(record) => {
            return record.orderIdCreate ?? '';
          }}
        />
      </div>
      <ModalDetailHistory isOpen={opened} setIsOpenModal={setOpened} detail={detailHistory} />
    </>
  );
}
