import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import { getConnectionBatchList } from 'src/api/connection';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { connectionStatus } from 'src/shared/enum/connection.enum';
import { SimType } from 'src/shared/enum/sim.enum';
import { IConnectionBatch } from 'src/shared/interface/connection';
import { exportExcel, parseQueryString } from 'src/utils';
import { connectionTypeToString } from 'src/utils/connection';
import { simTypeToText } from 'src/utils/sim.enum';

const BatchConnection = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<PaginationResponse<[IConnectionBatch]>>();
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const location = useLocation();
  const params = location.search;
  const { openTabs, listPackageType } = useAppSelector((state) => state.global);
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().endOf('day');
  };

  const openCreateOrderPage = () => {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'connection/batch/list',
      }),
    );
    if (!openTabs.find((tab) => tab.key === 'connection/batch/list')) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Tạo mới lô đấu nối',
              children: '',
              key: 'connection/batch/list',
              closable: true,
            },
          ],
        }),
      );
    }
    dispatch(setGlobalState({ order: undefined }));
    navigate(`/management/connection/batch/list`);
  };

  const handleDetailOrder = (order: any) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'connection/batch/list' + '?id=' + order.id,
      }),
    );
    if (!openTabs.find((tab) => tab.key === 'connection/batch/list' + '?id=' + order.id)) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem chi tiết lô đấu nối ' + order.id,
              children: '',
              key: 'connection/batch/list' + '?id=' + order.id,
              closable: true,
            },
          ],
        }),
      );
    }
    navigate(`/management/connection/batch/list?id=${order.id}`);
  };

  const handleFormSearch = (value: any) => {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });

    setSearchParams({ ...searchParams, ...values });
  };

  const fetchListConnectionBatch = async () => {
    const params = parseQueryString(location.search);
    const res = await getConnectionBatchList(params);
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchListConnectionBatch();
    setSortColumn(undefined);
  }, [location.search]);

  const handleExport = () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_dau_noi_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const url =
      process.env.REACT_APP_API_URL +
      '/api/Provider/active-sim-lot/export?distributorId=' +
      JSON.parse(merchantInfo as string)?.merchantId +
      params.replace('?', '&');
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Mã lô',
      fieldName: 'id',
      renderSpecialCard: (order) => {
        return (
          <button
            className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailOrder(order)}
          >
            {order.id ?? ''}
          </button>
        );
      },
    },
    {
      name: 'Loại',
      fieldName: 'connectionType',
      renderSpecialCard: (record) => {
        return (
          <span>{record.connectionType && connectionTypeToString(record.connectionType)}</span>
        );
      },
    },
    {
      name: 'SIM',
      fieldName: 'simType',
      renderSpecialCard: (record) => {
        return <span>{simTypeToText(Number(record?.simType))}</span>;
      },
    },
    // {
    //   name: 'Loại gói',
    //   fieldName: 'packageType',
    //   isHideSort: true,
    //   renderSpecialCard: (record) => {
    //     return (
    //       <Typography.Text>
    //         {listPackageType?.find((item) => item.description === record?.packageType)?.name ??
    //           'Gói thường'}
    //       </Typography.Text>
    //     );
    //   },
    // },
    { name: 'Trạng thái', fieldName: 'statusString' },
    { name: 'Tổng cộng', fieldName: 'countSerial', isHideSort: true },
    { name: 'Số lượng thành công', fieldName: 'countSerialSuccess', isHideSort: true },
    { name: 'Số lượng thất bại', fieldName: 'countSerialFailed', isHideSort: true },
    { name: 'Nguời tạo', fieldName: 'createdBy' },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record) => {
        return (
          <span>{record.createdAt && moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
        );
      },
    },
    { name: 'Nguời cập nhật cuối', fieldName: 'updatedBy' },
    {
      name: 'Ngày cập nhật cuối',
      fieldName: 'updatedAt',
      renderSpecialCard: (record) => {
        return (
          <span>{record.updatedAt && moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
        );
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (order) => {
        return (
          <button
            className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailOrder(order)}
          >
            Chi tiết
          </button>
        );
      },
      isHideSort: true,
    },
  ];


  return (
    <div>
      <Form onFinish={handleFormSearch} layout='inline' className='gap-2'>
        <Form.Item name='search'>
          <Input prefix={<SearchOutlined />} placeholder='Mã lô' />
        </Form.Item>
        <Form.Item name='connectionType' label='Loại đấu nối'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            defaultValue={''}
            options={[
              { value: '', label: 'Tất cả' },
              { value: 'Enterprise', label: 'Doanh nghiệp' },
              { value: 'Individual', label: 'Cá nhân' },
            ]}
          ></Select>
        </Form.Item>
        {/* <Form.Item name='subType' label='Loại thuê bao'>
          <Select
            showSearch
            className='min-w-[200px]'
            placeholder='Vui lòng chọn'
            defaultValue={''}
            options={renderOptionSubType()}
          ></Select>
        </Form.Item> */}
        <Form.Item name='simType' label='SIM'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            defaultValue={''}
            options={[
              {
                value: '',
                label: 'Tất cả',
              },
              {
                value: SimType.PHYSICAL_SIM,
                label: simTypeToText(SimType.PHYSICAL_SIM),
              },
              {
                value: SimType.ESIM,
                label: simTypeToText(SimType.ESIM),
              },
            ]}
          ></Select>
        </Form.Item>
        {/* <Form.Item name='PackageType' label='Loại gói'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            defaultValue={''}
            options={[
              {
                value: '',
                label: 'Tất cả',
              },
              ...(listPackageType || []).map((item) => ({
                label: item.name,
                value: item.description,
              })),
            ]}
          ></Select>
        </Form.Item> */}
        <Form.Item name='StatusString' label='Trạng thái'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            defaultValue={null}
            options={connectionStatus}
          ></Select>
        </Form.Item>
        <Form.Item name='dateRange' label='Thời gian tạo'>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            placeholder={['Từ ngày', 'đến ngày']}
            format={['DD/MM/YYYY', 'DD/MM/YYYY']}
          />
        </Form.Item>
        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={handleExport}>Export</Button>
          <Button type='primary' onClick={openCreateOrderPage}>
            Tạo mới
          </Button>
        </div>
      </Form>
      <div className='mt-6'>
        <TableContent
          bordered
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          dataSource={data?.results ?? []}
          columns={columns}
          totalRecord={data?.rowCount ?? 0}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleFormSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc:
                sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
            });
          }}
        />
      </div>
    </div>
  );
};

export default BatchConnection;
