import {
  mbfCardTypeIds,
  SimStatus,
  SubscriptionStatus,
  TypeCardID,
  ucmCardTypeIds,
  EnumJointSubject,
  EnumActiveSimStatus,
  SubscriptionStatusOption,
} from '../constants';
import { EnterpriseStatusEnum, EnterpriseTypeEnum } from 'src/shared/enum/business_profile';

export const getSubscriptionStatus = (status: any) => {
  switch (status) {
    case SubscriptionStatus.All:
      return 'Tất cả';
    case SubscriptionStatus.BLOCK_1_WAY:
      return 'Khóa 1 chiều';
    case SubscriptionStatus.BLOCK_2_WAYS:
      return 'Khóa 2 chiều';
    case SubscriptionStatus.UNSUBSCRIPTION:
      return 'Hủy';
    case SubscriptionStatus.UNLOCK:
      return 'Hoạt động';
    default:
      return '';
  }
};

export const getSubscriptionStatusOption = (): any => {
  const subscriptionStatusOption = Object.values(SubscriptionStatus).filter(
    (item) => !isNaN(Number(item)),
  );
  return subscriptionStatusOption?.map((status) => ({
    value: status,
    label: getSubscriptionStatus(status),
  }));
};

export const getTypeCardID = (type: any) => {
  switch (type) {
    case TypeCardID.CMND:
      return 'CMND';
    case TypeCardID.CCCD:
      return 'CCCD';
    case TypeCardID.Passport:
      return 'Passport';
    default:
      return '';
  }
};

export const getTypeCardIDOption = (): any => {
  const options = Object.values(TypeCardID).filter((item) => !isNaN(Number(item)));
  return options?.map((type) => ({
    value: type,
    label: getTypeCardID(type),
  }));
};

export const isValidUcmCardTypeID = (type: any) => {
  return Object.values(ucmCardTypeIds).includes(parseInt(type));
};

export const getUcmCardTypeID = (type: any) => {
  switch (type) {
    case ucmCardTypeIds.cmnd_cccd:
      return 'CMND/CCCD';
    case ucmCardTypeIds.passport:
      return 'Passport';
    default:
      return 'Unknown';
  }
};

export const getUcmCardTypeIDOption = (): any => {
  return Object.values(ucmCardTypeIds).map((type) => ({
    value: type.toString(),
    label: getUcmCardTypeID(type),
  }));
};

export const getSimStatus = (status: any) => {
  switch (status) {
    case EnumActiveSimStatus.AVAILABLE:
      return 'Chưa được bán';
    case EnumActiveSimStatus.INVALID_SIM:
      return 'Sim lỗi';
    case EnumActiveSimStatus.EXPIRED_SIM:
      return 'Sim hết hạn';
    case EnumActiveSimStatus.ACTIVATION_SIM:
      return 'Đang hoạt động';
    case EnumActiveSimStatus.NOT_ACTIVE:
      return 'Sim đã bán nhưng chưa kích hoạt';
    case EnumActiveSimStatus.CANCEL:
      return 'Sim bị huỷ';
    case EnumActiveSimStatus.WAITING_APPROVE:
      return 'Chờ duyệt hồ sơ';
    default:
      return '';
  }
};

export const getSimStatusBasic = (status: any) => {
  switch (status) {
    case SimStatus.ACTIVATION_SUCCESS:
      return 'Active';
    case SimStatus.CANCEL:
      return 'Cancel';
    default:
      return 'Tất cả';
  }
};

export const getSimStatusOption = (): any => {
  const simStatusOption = Object.values(SimStatus).filter((item) => !isNaN(Number(item)));
  return simStatusOption?.map((status) => ({
    value: status,
    label: getSimStatusBasic(status),
  }));
};

export const getJointSubject = (data: any) => {
  switch (data) {
    case EnumJointSubject.PERSONAL:
      return 'Cá nhân';
    case EnumJointSubject.ENTERPRISE:
      return 'Doanh nghiệp';
    default:
      return '';
  }
};
export const getJointSubjectSim = (data: any) => {
  switch (data) {
    case EnumJointSubject.PERSONAL:
      return 'Cá nhân';
    case EnumJointSubject.ENTERPRISE:
      return 'Doanh nghiệp';
    default:
      return '';
  }
};

export const getJointSubjectOption = (): any => {
  const subscriberOwnerOption = Object.values(EnumJointSubject);
  return subscriberOwnerOption?.map((status) => ({
    value: status,
    label: getJointSubjectSim(status),
  }));
};

export const getTypeBusiness = (typeBusiness: any) => {
  switch (typeBusiness) {
    case EnterpriseTypeEnum.DN:
      return 'DN';
    case EnterpriseTypeEnum.HKDL:
      return 'HKDL';
    case EnterpriseTypeEnum.CovinienceStore:
      return 'CovinienceStore';
    case EnterpriseTypeEnum.M2M:
      return 'M2M';
    default:
      return '';
  }
};

export const genEnterpriseTypeOptions = (): any => {
  const enterpriseTypeOptions = Object.values(EnterpriseTypeEnum).filter((v) => !isNaN(Number(v)));
  return enterpriseTypeOptions.map((type) => ({
    value: type.toString(),
    label: getTypeBusiness(type),
  }));
};

export const getStatusBusinessProfile = (statusBusiness?: EnterpriseStatusEnum) => {
  switch (statusBusiness) {
    case EnterpriseStatusEnum.NEW:
      return 'NEW';
    case EnterpriseStatusEnum.WAITING:
      return 'WAITING';
    case EnterpriseStatusEnum.EDITED:
      return 'EDITED';
    case EnterpriseStatusEnum.APPROVED:
      return 'APPROVED';
    case EnterpriseStatusEnum.REJECTED:
      return 'REJECTED';
    default:
      return 'DEFAULT';
  }
};

export const getStatusColorBusinessProfile = (statusBusiness?: EnterpriseStatusEnum) => {
  switch (statusBusiness) {
    case EnterpriseStatusEnum.NEW:
      return 'cyan';
    case EnterpriseStatusEnum.WAITING:
      return 'geekblue';
    case EnterpriseStatusEnum.EDITED:
      return 'orange';
    case EnterpriseStatusEnum.APPROVED:
      return 'green';
    case EnterpriseStatusEnum.REJECTED:
      return 'red';
    default:
      return 'cyan';
  }
};
export const listSubscriptionStatus = (status: any) => {
  switch (status) {
    case SubscriptionStatusOption.CANCELED:
      return 'Đã huỷ';
    case SubscriptionStatusOption.ACTIVE:
      return 'Đang hoạt động';
    default:
      return '';
  }
};
export const listSubscriptionStatusOption = (): any => {
  const subscriptionStatusOption = Object.values(SubscriptionStatusOption).filter(
    (item) => !isNaN(Number(item)),
  );
  return subscriptionStatusOption?.map((status) => ({
    value: status,
    label: listSubscriptionStatus(status),
  }));
};
