import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMerchant } from 'src/api/user_profile';
import { store } from 'src/redux';
import './index.scss';
import { parseJwt } from 'src/utils';
import { AccountRole } from 'src/shared/enum/business_profile';
import { getMasterData } from 'src/api';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { useAppSelector } from 'src/redux/hook';
dayjs.extend(isBetween);

function Login() {
  const { masterData } = useAppSelector(state => state.global);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { requestId, deviceId } = store.getState().global;
  const [isTimeActive, setIsTimeActive] = useState(false);

  const checkActiveTime = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Provider/get-time-active-available`)
      .then((res) => {
        const timeStart = res?.data?.data.timeFrom;
        const timeEnd = res?.data?.data.timeTo;
        const currentHour = dayjs().format('H:mm:ss');

        const isTimeBetween = dayjs(`2016-01-01 ${currentHour}`).isBetween(
          `2016-01-01 ${timeStart}`,
          `2016-01-01 ${timeEnd}`,
          `millisecond`,
          `[)`,
        );
        // const isTimeBetween = dayjs(`2016-01-01 ${currentHour}`).isBetween(`2016-01-01 1:00:00`, `2016-01-01 2:00:00`, `millisecond`, `[)`);

        setIsTimeActive(isTimeBetween);
      });
  };

  useEffect(() => {
    checkActiveTime();
  }, []);

  const onFinish = async (values: any) => {
    const config = {
      headers: {
        requestId,
        deviceId,
      },
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_AUTH_TELCO_NPP_URL}/login`,
        { ...values, appCode: 'TELCO_NPP' },
        config,
      )
      .then(async (res) => {
        if (res?.data?.response) {
          //   const dataToken = parseJwt(res?.data?.response.accessToken);

          const token = res?.data?.response.accessToken;
          const refreshToken = res?.headers?.refreshtoken;
          const tokenType = res?.data?.response.tokenType;
          localStorage.setItem('token', token.toString());
          localStorage.setItem('isKHDN', 'KHDN');
          localStorage.setItem('refreshToken', refreshToken.toString());
          localStorage.setItem('tokenType', tokenType.toString());
          const resMerchant = await getMerchant();
          const resMasterDataSubType = await getMasterData({ code: "subType" })
          if (resMerchant) {
            console.log(resMerchant);
            localStorage.setItem('merchantInfo', JSON.stringify(resMerchant));
          }
          if (resMasterDataSubType) {
            store.dispatch(setGlobalState({
              masterData: {
                ...masterData,
                subType: resMasterDataSubType
              }
            }))
          }
          navigate('/management');
        } else {
          axios
            .post(
              `${process.env.REACT_APP_AUTH_SSO_URL}/auth-service/v1/login`,
              { ...values, appCode: 'TELCO' },
              config,
            )
            .then((res) => {
              if (res?.data?.response) {
                if (isTimeActive) {
                  const token = res?.data?.response.accessToken;
                  const refreshToken = res?.headers?.refreshtoken;
                  const tokenType = res?.data?.response.tokenType;
                  localStorage.setItem('isKHDN', 'NPP');
                  localStorage.setItem('token', token.toString());
                  localStorage.setItem('refreshToken', refreshToken.toString());
                  localStorage.setItem('tokenType', tokenType.toString());
                  navigate('/');
                } else {
                  message.error('Chưa trong thời gian hoạt động! Vui lòng thử lại sau');
                }
              } else {
                if (res?.data?.errorMessage === 'Error') {
                  message.error('Tài khoản và mật khẩu không trùng khớp');
                }
              }
            });
        }
      })
      .catch((err) =>
        message.error({
          content: err.response?.data?.message,
          style: {
            top: '50%',
          },
        }),
      )
      .finally(() => setLoading(false));
  };

  //   const login = async (values: any) => {
  //     axios
  //       .post(`${process.env.REACT_APP_KHDN_AUTH_URL}/api/user/get-tokens`, values)
  //       .then((res) => {
  //         const token = res.headers.accesstoken;
  //         const role = res.headers.role;
  //         const merchantInfo = res?.data?.body;
  //         localStorage.setItem('merchantInfo', JSON.stringify({ ...merchantInfo, role }));
  //         localStorage.setItem('token', token.toString());

  //         if (role === 'MERCHANT_KHDN') {
  //           navigate('/management');
  //         } else {
  //           message.error('Tài khoản chưa đăng ký');
  //           return;
  //         }
  //       })
  //       .catch((err) =>
  //         message.error({
  //           content: err.response?.data?.message,
  //           style: {
  //             top: '50%',
  //           },
  //         }),
  //       )
  //       .finally(() => setLoading(false));
  //   };

  return (
    <div className='w-screen h-screen wrapper'>
      <div className='p-6 login-form'>
        <Form onFinish={onFinish}>
          <h4 className='text-center'>Đăng nhập</h4>
          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên tài khoản!',
              },
            ]}
            className={'w-full'}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='Nhập tên tài khoản'
              className='text_area'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              },
            ]}
            className={'mt-2 w-full'}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Mật khẩu'
              className='text_area'
            />
          </Form.Item>

          <Form.Item className=' w-full flex justify-center mt-6'>
            <Button
              loading={loading}
              type='primary'
              htmlType='submit'
              className='btn-submit bg-[#1677ff]'
            >
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
