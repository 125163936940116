export const ucmCardTypeIds = {
  cmnd_cccd: 0,
  passport: 1,
};

export const mbfCardTypeIds = {
  cmnd_cccd: 1,
  passport: 2,
};

export enum TypeCardID {
  CMND = 1,
  CCCD = 2,
  Passport = 3,
}

export enum SubscriptionStatus {
  All = -1,
  BLOCK_1_WAY = 1,
  BLOCK_2_WAYS = 2,
  UNSUBSCRIPTION = 3,
  UNLOCK = 4,
}

export enum SimStatus {
  ACTIVATION_SUCCESS = '6', //Inusage
  CANCEL = '7',
}

export enum JointSubject {
  All = '-1',
  PERSONAL = 'personal',
  ENTERPRISE = 'enterprise',
}
export enum EnumJointSubject {
  PERSONAL = '2',
  ENTERPRISE = '1',
}
export enum EnumActiveSimStatus {
  AVAILABLE = '1',
  INVALID_SIM = '2',
  EXPIRED_SIM = '4',
  ACTIVATION_SIM = '6',
  NOT_ACTIVE = '5',
  CANCEL = '7',
  WAITING_APPROVE = '12',
}
export enum SubscriptionStatusOption {
  CANCELED = '0',
  ACTIVE = '1',
}
