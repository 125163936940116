import { Button, Col, Descriptions, Modal, Row, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DetailHistorySubcriber, HistorySubcriber } from 'src/@types/search-params';
import { getSearchDetailHistorySubcriber } from 'src/api/activeSim';
import { dataGet, formatDate } from 'src/utils';

interface ModalCheckEsimExpiredProps {
  isOpen: boolean;
  setIsOpenModal: any;
  detail?: HistorySubcriber;
}
export default function ModalDetailHistory({
  isOpen,
  setIsOpenModal,
  detail,
}: ModalCheckEsimExpiredProps) {
  const [dataDetail, setDataDetail] = useState<DetailHistorySubcriber[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSimHistoryChangeData = async () => {
      try {
        setIsLoading(true);
        if (detail) {
          const res = await getSearchDetailHistorySubcriber(detail);
          setDataDetail(res);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSimHistoryChangeData();
  }, [detail]);

  const columns = [
    {
      title: 'STT',
      width: 40,
      render: (text: string, record: any, index: number) => {
        return index + 1;
      },
    },
    {
      title: 'Trường thông tin',
      dataIndex: 'field',
      key: 'field',
      width: 100,
      render: (text: string) => {
        return <Typography.Text>{text ? text : ''}</Typography.Text>;
      },
    },
    {
      title: 'Giá trị cũ',
      dataIndex: 'oldValue',
      width: 150,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Giá trị mới',
      dataIndex: 'newValue',
      width: 150,
      render: (text: string) => {
        return <Typography.Text className={'text-center'}>{text}</Typography.Text>;
      },
    },
  ];

  return (
    <>
      <Modal
        open={isOpen}
        width={'60%'}
        onCancel={() => setIsOpenModal(false)}
        title={'Chi tiết tác động'}
        footer={false}
        maskClosable={false}
      >
        <div className={'py-5 '}>
          <Row gutter={16}>
            <Col span={24}>
              <Descriptions
                column={1}
                labelStyle={{
                  minWidth: '185px',
                }}
              >
                <Descriptions.Item label='Tác động'>{dataGet(detail, 'name')}</Descriptions.Item>
                <Descriptions.Item label='Thời gian cập nhật'>
                  {dataGet(detail, 'createdDate')}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24}>
              <div className={'sim-history-change-tab '}>
                <Table
                  loading={isLoading}
                  bordered
                  className='max-h-[500px] overflow-y-auto'
                  columns={columns}
                  dataSource={dataDetail}
                  rowKey={(record) => {
                    return record.column ?? '';
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className={'flex items-center justify-end mt-12'}>
            <Button className={'ml-2'} onClick={() => setIsOpenModal(false)}>
              Huỷ
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
