import { Col, Row, Form, Input, Select, DatePicker, Radio } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import dayjs from 'dayjs';
import { getUcmCardTypeIDOption, isValidUcmCardTypeID } from 'src/pages/management/activeSim/utils';
import { DEFAULT_DATE_FORMAT } from 'src/shared/constants';
import TextArea from 'antd/es/input/TextArea';

export default function SimAgentInfoBox({ accountAttribute }: any) {
  const [form] = Form.useForm();

  const defaultValue = {
    ...accountAttribute,
    contactIdType: isValidUcmCardTypeID(accountAttribute?.contactIdType)
      ? accountAttribute?.contactIdType
      : null,
    contactDob: accountAttribute?.contactDob ? dayjs(accountAttribute?.contactDob) : null,
    contactIssueDate: accountAttribute?.contactIssueDate
      ? dayjs(accountAttribute?.contactIssueDate)
      : null,
    contactFullAddress: `${accountAttribute?.contactAddress}, ${accountAttribute?.contactWardName}, ${accountAttribute?.contactDistrictName}, ${accountAttribute?.contactProvinceName}`,
  };

  return (
    <BoxWithLabel title='Thông tin người đại diện' classNames='mt-4'>
      <Form
        form={form}
        labelWrap={true}
        initialValues={defaultValue}
        disabled={true}
        labelCol={{ span: 6 }}
      >
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              label='Họ và tên'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactName'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày sinh'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactDob'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Giới tính'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactGender'}
            >
              <Radio.Group>
                <Radio value={'0'}>Nam</Radio>
                <Radio value={'1'}>Nữ</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='Quốc tịch'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactNationalityName'}
            >
              <Select />
            </Form.Item>
            <Form.Item
              label='Loại giấy tờ'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactIdType'}
            >
              <Select options={getUcmCardTypeIDOption()} />
            </Form.Item>
            <Form.Item
              label='Số giấy tờ'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactIdNumber'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày cấp'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactIssueDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Nơi cấp'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactIssuePlaceName'}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  label='Địa chỉ thường trú'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactFullAddress'}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  label='Tỉnh/TP'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactProvinceName'}
                >
                  <Select />
                </Form.Item>
              </Col> */}
            </Row>
            {/* <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item
                  label='Quận/Huyện'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactDistrictName'}
                >
                  <Select />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Phường/Xã'
                  labelCol={{ span: 10 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactWardName'}
                >
                  <Select />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  label='Nghề nghiệp'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactEmployment'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Chức vụ'
                  labelCol={{ span: 8 }}
                  labelAlign={'left'}
                  required={true}
                  name={'contactRole'}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Email'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactEmail'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Số giấy ủy quyền'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name='authorNo'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Ngày cấp giấy UQ'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name={'authorStartDate'}
            >
              <DatePicker className={'w-full'} format={DEFAULT_DATE_FORMAT} placeholder={''} />
            </Form.Item>
            <Form.Item
              label='Người ký giấy UQ'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name={'authorPowerOfAttorneyGrantor'}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label='Số điện thoại'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactPhoneNumber'}
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              label='Ghi chú'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name={'contactNote'}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label='Liên hệ khác'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              name={'contactOtherContact'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Là người đến giao dịch?'
              labelCol={{ span: 8 }}
              labelAlign={'left'}
              required={true}
              name={'contactIsNegotiator'}
            >
              <Radio.Group>
                <Radio value={'true'}>Có</Radio>
                <Radio value={'false'}>Không</Radio>
              </Radio.Group>
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
    </BoxWithLabel>
  );
}
