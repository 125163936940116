/* eslint-disable no-unsafe-optional-chaining */
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Radio, Row, Spin, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkStatusSerial, getConnectionHistoryDetail, historyRetry } from 'src/api/connection';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppSelector } from 'src/redux/hook';
import { documentTypeToText } from 'src/shared/enum/connection.enum';
import { SimType } from 'src/shared/enum/sim.enum';
import {
  formatDate,
  formatDateTime,
  formatPhoneNumberV2,
  handleDownloadImage,
  parseQueryString,
  renderSubType,
} from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';


const BatchConnectionHistoryDetail = () => {
  const { masterData } = useAppSelector(state => state.global)
  const token = localStorage.getItem('token');
  const [form] = useForm();
  const options = ['Cá nhân', 'Doanh nghiệp'];
  const [type, setType] = useState('Doanh nghiệp');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldTimeUpdate, setOldTimeUpdate] = useState(null);
  const { listPackageType } = useAppSelector((state) => state.global);

  const [detailData, setDetailData] = useState<any>();
  const { id, orderId } = parseQueryString(location.search);

  let intervalID: any;
  const countDown = Date.now();

  const fetchDetailOrder = async () => {
    const res = await getConnectionHistoryDetail({
      Serial: id,
      OrderId: orderId,
    });

    if (res) {
      setDetailData(res);
      setType(res?.connectionType === 'Enterprise' ? 'Doanh nghiệp' : 'Cá nhân');
      setOldTimeUpdate(res?.updatedAt);
    }
  };

  useEffect(() => {
    fetchDetailOrder();
  }, []);

  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const handleRetry = async () => {
    setIsLoading(true);
    try {
      const response = await historyRetry(detailData?.connectionType, {
        distributorId: JSON.parse(merchantInfo as string)?.merchantId,
        serialNumber: detailData?.serial,
        serialId: detailData?.id,
      });

      if (response) {
        if (response.data.isSucceeded || response.isSucceeded) {
          message.success('Đấu nối thành công!');
          navigate(0);
        } else {
          message.error(response?.data?.errorMessage);
        }
      } else {
        if (countDown + 2 * 60 * 1000 > Date.now()) {
          intervalID = setInterval(checkStatus, 5000);
        } else {
          clearInterval(intervalID);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  function checkStatus() {
    setIsLoading(true);
    checkStatusSerial(detailData?.serial || '').then((res) => {
      if (res?.updatedAt !== oldTimeUpdate) {
        if (res?.statusString !== 'Failed') {
          message.success('Đấu nối thành công');
          navigate(0);
        } else {
          message.error(res?.errorMessage);
        }
        setIsLoading(false);
        navigate(0);
      }
    });
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className='flex flex-col gap-10'>
          <Form
            form={form}
            initialValues={{
              device: 'Doanh nghiệp',
            }}
          >
            <div>
              <Row gutter={24} className='w-full'>
                <Col span={10}>
                  <Form.Item label='Loại đấu nối' labelCol={{ span: 8 }}>
                    <Radio.Group options={options} value={type} disabled={true} />
                  </Form.Item>
                  <Form.Item label='Số SIM serial' name='status' labelCol={{ span: 8 }}>
                    {detailData?.serial}
                  </Form.Item>
                  <Form.Item label='Mã gói' name='status' labelCol={{ span: 8 }}>
                    {detailData?.mbfCode}
                  </Form.Item>
                  <Form.Item label='Số thuê bao' name='status' labelCol={{ span: 8 }}>
                    {`${(detailData?.phoneNumber && formatPhoneNumberV2(detailData?.phoneNumber)) ??
                      ''
                      }`}
                  </Form.Item>
                  <Form.Item label='Loại thuê bao' name='subStatus' labelCol={{ span: 8 }}>
                    {renderSubType(masterData, detailData?.subType)}
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <div>
                    <Form.Item label='Mã lô'>
                      {(detailData?.type === 'UserProfileActiveLot' ||
                        detailData?.type === 'ActiveLot') &&
                        detailData?.orderId}
                    </Form.Item>
                    <div className='flex gap-10'>
                      <Form.Item label='Trạng thái đấu nối'>{detailData?.statusString}</Form.Item>
                      {detailData?.statusString === 'Fail' && (
                        <>
                          <Form.Item label='Mã lỗi'>{detailData?.errorCode}</Form.Item>
                          <Form.Item label='Mô tả lỗi'>{detailData?.errorMessage}</Form.Item>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <Form.Item label='Loại gói'>
                      {listPackageType?.find((item) => item.description === detailData?.packageType)
                        ?.name ?? 'Gói thường'}
                    </Form.Item>
                  </div>
                  <div className='flex gap-10'>
                    <Form.Item label='Loại'>{simTypeToText(detailData?.orderSimType)}</Form.Item>
                    <Form.Item label='NPP'>{detailData?.enterpriseName}</Form.Item>
                    <Form.Item label='Trạng thái'>
                      {detailData?.statusString === 'Success' ? 'Active' : 'Inactive'}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {detailData?.statusString === 'Success' &&
                detailData?.orderSimType === SimType.ESIM && (
                  <div className={'justify-items-start'}>
                    <div className={'mb-2'}>Mã QR eSIM để thêm vào thiết bị:</div>
                    {detailData?.linkQrText && detailData?.linkQrText !== null && (
                      <div className={'flex items-end'}>
                        <Image
                          src={detailData?.linkQrText}
                          alt='qr'
                          width={100}
                          height={100}
                        ></Image>
                        <div className={'flex items-end ml-2'}>
                          <button
                            className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold leading-3`}
                            onClick={() => handleDownloadImage(detailData?.linkQrText)}
                          >
                            Tải về
                          </button>
                          <DownloadOutlined
                            className='text-xl text-black cursor-pointer'
                            onClick={() => handleDownloadImage(detailData?.linkQrText)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
            {/* userProfile */}
            <p style={{ marginTop: '20px' }}></p>
            {detailData?.userProfile && (
              <>
                <BoxWithLabel title='Thông tin người sử dụng'>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label='ID hồ sơ'>{detailData?.userProfileId}</Form.Item>
                      <Form.Item label='Batch ID'>{detailData?.userProfile?.lotId}</Form.Item>
                      <Form.Item label='ID hồ sơ DN'>{`DN0${detailData?.enterpriseId}-${detailData?.enterpriseName}`}</Form.Item>
                      <Form.Item label='Họ và tên'>{detailData?.userProfile?.userName}</Form.Item>
                      <Form.Item label='Ngày sinh'>
                        {detailData?.userProfile?.birthDay &&
                          formatDate(detailData?.userProfile?.birthDay)}
                      </Form.Item>
                      <Form.Item label='Giới tính'>
                        {detailData?.userProfile?.gender === 0 ? 'Nam' : 'Nữ'}
                      </Form.Item>
                      <Form.Item label='Quốc tịch'>
                        {detailData?.userProfile?.nationality}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label='Loại giấy tờ'>
                        {documentTypeToText(detailData?.userProfile?.typeIdNumber)}
                      </Form.Item>
                      <Form.Item label='Số giấy tờ'>{detailData?.userProfile?.idNumber}</Form.Item>
                      <Form.Item label='Ngày cấp'>
                        {detailData?.userProfile?.issueDate &&
                          formatDate(detailData?.userProfile?.issueDate)}
                      </Form.Item>
                      <Form.Item label='Nơi cấp'>
                        {detailData?.userProfile?.issuePlaceString}
                      </Form.Item>
                      <Form.Item label='Địa chỉ thường trú'>
                        {`${detailData?.userProfile?.address}`}
                      </Form.Item>
                      <Form.Item label='Số lượng TB active'>
                        {detailData?.userProfile?.countSimActive}
                      </Form.Item>
                      {!!detailData?.userProfileId && (
                        <>
                          <Form.Item label='Trạng thái hoạt động'>
                            {detailData?.userProfile?.isActive ? 'Active' : 'Inactive'}
                          </Form.Item>
                          <Form.Item label='Trạng thái hồ sơ'>
                            {detailData?.userProfile?.statusString}
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Row>
                </BoxWithLabel>
                <p style={{ margin: '40px 0' }}></p>
                <BoxWithLabel title='Hình ảnh'>
                  <div className='flex gap-2 text-center w-full justify-between'>
                    <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
                      <img
                        className='max-w-full'
                        src={detailData?.userProfile?.frontImage}
                        alt='Mặt trước CMND'
                      ></img>
                      <p className='absolute left-0 bottom-0 w-full text-center'>Mặt trước CMND</p>
                    </div>
                    <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
                      <img
                        className='max-w-full'
                        src={detailData?.userProfile?.backImage}
                        alt='Mặt sau CMND'
                      ></img>
                      <p className='absolute left-0 bottom-0 w-full text-center'>Mặt sau CMND</p>
                    </div>
                    <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
                      <img
                        className='max-w-full'
                        src={detailData?.userProfile?.signature}
                        alt='Chữ ký'
                      ></img>
                      <p className='absolute left-0 bottom-0 w-full text-center'>Chữ ký</p>
                    </div>
                    <div className='flex-1 w-[25%] max-w-[250px] relative pb-14 flex items-center justify-center'>
                      <img
                        className='max-w-full'
                        src={detailData?.userProfile?.selfieImage}
                        alt='Chân dung'
                      ></img>
                      <p className='absolute left-0 bottom-0 w-full text-center'>Chân dung</p>
                    </div>
                  </div>
                </BoxWithLabel>
              </>
            )}
            <p style={{ marginTop: '40px' }}></p>
            <BoxWithLabel title=''>
              <Row gutter={24} className='w-full'>
                <Col span={10}>
                  <Form.Item label='Người tạo' labelCol={{ span: 8 }}>
                    {detailData?.createdBy}
                  </Form.Item>
                  <Form.Item label='Thời gian tạo' name='status' labelCol={{ span: 8 }}>
                    {detailData?.createdAt && formatDateTime(detailData?.createdAt)}
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item label='Người cập nhật gần nhất'>{detailData?.updatedBy}</Form.Item>
                  <Form.Item label='Thời gian cập nhật gần nhất'>
                    {detailData?.updatedAt && formatDateTime(detailData?.updatedAt)}
                  </Form.Item>
                </Col>
              </Row>
            </BoxWithLabel>
            <p style={{ marginTop: '40px' }}></p>
            {detailData?.statusString === 'Fail' && (
              <Button type='primary' size='large' onClick={handleRetry}>
                Retry
              </Button>
            )}
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default BatchConnectionHistoryDetail;
