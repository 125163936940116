import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn } from 'src/@types/search-params';
import { getSearchActiveSim } from 'src/api/activeSim';
import TableContent from 'src/components/CTable';
import { OpenNewTab } from 'src/shared/common/openNewTab';
import { dataGet, formatDate, formatPhoneNumber, parseQueryString } from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';
import { getJointSubject, getSimStatus, getUcmCardTypeID } from '../../utils';

interface FormData {
  isdn?: string;
  serial?: string;
}

const SearchSim = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataSource, setDataSource] = useState();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();

  const fetchActiveSimInfoData = async () => {
    try {
      const res = await getSearchActiveSim(location.search);
      setDataSource(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { serial, isdn } = parseQueryString(location.search);
    if (serial || isdn) {
      fetchActiveSimInfoData();
    }
  }, [location.search]);

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', align: 'center', isHideSort: true, width: 50 },
    {
      name: 'SĐT',
      fieldName: 'isdn',
      align: 'center',
      width: 150,
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {record.isdn ? '0' + formatPhoneNumber(record.isdn) : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Serial',
      fieldName: 'serial',
      align: 'center',
      width: 150,
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            {record?.serial}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Loại SIM',
      fieldName: 'simType',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {simTypeToText(Number(record.simType) + 1)}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Ngày gán gói ban đầu',
      fieldName: 'kittingDate',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'kittingDate') ? dataGet(record, 'kittingDate').split(' ')[0] : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Gói cước ban đầu',
      fieldName: 'goodCode',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{dataGet(record, 'goodCode')}</Typography.Text>;
      },
    },
    {
      name: 'Ngày kích hoạt',
      fieldName: 'activeDate',
      isHideSort: true,
      align: 'center',
      width: 120,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'activeDate') ? dataGet(record, 'activeDate').split(' ')[0] : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Mã hồ sơ DN',
      fieldName: 'companyCode',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Tên hồ sơ DN',
      fieldName: 'companyName',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Đối tượng',
      fieldName: 'custType',
      isHideSort: true,
      align: 'center',
      width: 150,
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {dataGet(record, 'custType') ? getJointSubject(dataGet(record, 'custType')) : ''}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Tên thuê bao',
      fieldName: 'subName',
      isHideSort: true,
      align: 'center',
      width: 200,
    },
    {
      name: 'Loại giấy tờ',
      fieldName: 'idType',
      isHideSort: true,
      width: 150,
      align: 'center',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text className='Text'>
            {getUcmCardTypeID(Number(dataGet(record, 'idType')))}
          </Typography.Text>
        );
      },
    },
    {
      name: 'Số giấy tờ',
      fieldName: 'idNo',
      isHideSort: true,
      align: 'center',
    },
    {
      name: 'Trạng thái TB',
      fieldName: 'status',
      isHideSort: true,
      width: 150,
      align: 'center',
      renderSpecialCard: (record) => {
        if (Number(record.status)) {
          return <Typography.Text className='Text'>{'Đang hoạt động'}</Typography.Text>;
        } else {
          return <Typography.Text className='Text'>{'Số đã huỷ'}</Typography.Text>;
        }
      },
    },
    {
      name: 'Trạng thái SIM',
      fieldName: 'activate',
      isHideSort: true,
      width: 180,
      align: 'center',
      renderSpecialCard: (record) => {
        return <Typography.Text className='Text'>{getSimStatus(record.activate)}</Typography.Text>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: '',
      renderSpecialCard: (record) => {
        return (
          <Typography.Text
            className={
              'underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold'
            }
            onClick={() => handleDetailActiveSim(record)}
          >
            Chi tiết
          </Typography.Text>
        );
      },
    },
  ];

  const handleSearch = (formValues: FormData) => {
    const { isdn, serial } = formValues;
    const searchParams = queryString.stringify({
      isdn: isdn ?? undefined,
      serial: serial ?? undefined,
    });
    setSearchParams(searchParams);
  };

  const defaultValues = {
    isdn: '',
    serial: '',
  };

  const handleDetailActiveSim = (data: any) => {
    OpenNewTab(
      `active-sim/detail?serial=${data?.serial}&phone=${data?.isdn}`,
      `Chi tiết thuê bao ${data?.isdn ? '0' + data?.isdn : ''}`,
    );
  };

  const handleDeleteFilter = () => {
    form.resetFields();
    setSearchParams({});
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={handleSearch}
          initialValues={defaultValues}
          layout='inline'
          className='gap-2'
        >
          <Row gutter={[5, 15]} className={'w-full'}>
            <Col xs={12}>
              <Form.Item name={'isdn'} label={'Số thuê bao'}>
                <Input allowClear placeholder='Nhập số điện thoại'></Input>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name={'serial'} label={'Sim serial'}>
                <Input allowClear placeholder='Nhập sim serial'></Input>
              </Form.Item>
            </Col>
          </Row>
          <div className={'row-button justify-end flex-wrap-reverse mt-3'}>
            <Button onClick={() => handleDeleteFilter()} className={'m-1'}>
              Xóa tìm kiếm
            </Button>
            <Button type={'primary'} htmlType='submit' className={'m-1'}>
              Tìm kiếm
            </Button>
          </div>
        </Form>
      </div>
      <div className='mt-4'>
        <TableContent
          bordered
          // loading={isLoading}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          totalRecord={dataGet(dataSource, 'rowCount', 0)}
          dataSource={dataGet(dataSource, 'results', [])}
          columns={columns}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
            });
          }}
        />
      </div>
    </>
  );
};

export default SearchSim;
