import { Col, Descriptions, Row, theme, Typography } from 'antd';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { getJointSubject, getSimStatusBasic } from 'src/pages/management/activeSim/utils';
import { dataGet } from 'src/utils';
import SimInfoTable from './SimInfoTable';

export default function SimInfoBox({ simDetail }: any) {
  const { token } = theme.useToken();

  return (
    <BoxWithLabel title='Thông tin thuê bao' classNames='mt-4'>
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions
            column={1}
            labelStyle={{
              minWidth: '185px',
              color: token.colorText,
            }}
          >
            <Descriptions.Item label='SĐT'>
              0{dataGet(simDetail, 'subcriberInfoBss.isdn')}
            </Descriptions.Item>
            <Descriptions.Item label='Số SIM Serial'>
              {dataGet(simDetail, 'subcriberInfoBss.serial')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày TB kích hoạt'>
              <Typography.Text>
                {simDetail?.subcriberInfoBss?.activeDate
                  ? simDetail.subcriberInfoBss.activeDate.split(' ')[0]
                  : ''}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Gói cước ban đầu'>
              {dataGet(simDetail, 'subcriberInfoBss.goodCode')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày gán gói ban đầu'>
              {dataGet(simDetail, 'subcriberInfoBss.kittingDate')
                ? dataGet(simDetail, 'subcriberInfoBss.kittingDate').split(' ')[0]
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Zone'>
              <Typography.Text>
                {dataGet(simDetail, 'custProfile.serviceModel.serviceValue.currentZone')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Ngày TB đổi SIM'>
              <Typography.Text>
                {simDetail?.subcriberInfoBss?.changeSimDate
                  ? simDetail.subcriberInfoBss.changeSimDate.split(' ')[0]
                  : ''}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions
            column={1}
            labelStyle={{
              minWidth: '180px',
              color: token.colorText,
            }}
          >
            <Descriptions.Item label='' className='w-full'>
              <SimInfoTable dataSim={simDetail} />
            </Descriptions.Item>
            <Descriptions.Item label='Số dư tài khoản chính'>
              <Typography.Text>{dataGet(simDetail, 'balance')}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Trạng thái TB'>
              <Typography.Text>
                {simDetail?.subcriberInfoBss?.status === '1' ? (
                  <Typography.Text className='Text'>{'Đang hoạt động'}</Typography.Text>
                ) : (
                  <Typography.Text className='Text'>{'Đã huỷ'}</Typography.Text>
                )}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Trạng thái chặn cắt'>
              <Typography.Text>
                {dataGet(simDetail, 'subcriberInfoBss.actStatus') === '00' ? (
                  <Typography.Text className='Text'>{'Đang hoạt động'}</Typography.Text>
                ) : ['01', '10', '11'].includes(
                    dataGet(simDetail, 'subcriberInfoBss.actStatus'),
                  ) ? (
                  <Typography.Text className='Text'>{'Đang chặn 1C'}</Typography.Text>
                ) : ['03'].includes(dataGet(simDetail, 'subcriberInfoBss.actStatus')) ? (
                  <Typography.Text className='Text'>{'Hủy thuê bao'}</Typography.Text>
                ) : (
                  <Typography.Text className='Text'>{'Đang chặn 2C'}</Typography.Text>
                )}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Trạng thái SIM'>
              <Typography.Text>
                {getSimStatusBasic(dataGet(simDetail, 'subcriberInfoBss.activate'))}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label='Đối tượng'>
              <Typography.Text>
                {getJointSubject(dataGet(simDetail, 'subcriberInfoBss.custType'))}
              </Typography.Text>
            </Descriptions.Item>

            {/* <Descriptions.Item label='Ngày kích hoạt gói cước'>
              {dataGet(simDetail, 'packageInfo.start_time')}
            </Descriptions.Item>
            <Descriptions.Item label='Ngày hết hạn gói cước'>
              {dataGet(simDetail, 'packageInfo.end_time')}
            </Descriptions.Item>
            <Descriptions.Item label='Data sử dụng'>
              <Space size={'small'}>
                <Typography.Text>{getDataUsedValue()}</Typography.Text>
                <Typography.Text>{dataGet(simDetail, 'packageInfo.unit')}</Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label='Số phút đã gọi'>
              <Space size={'small'}>
                <Typography.Text>{getCalledMinuteValue()}</Typography.Text>
                <Typography.Text>
                  {dataGet(simDetail, 'packageInfo.voice_call_unit')}
                </Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label='Loại SIM'>
              <Typography.Text>
                {simTypeToText(dataGet(simDetail, 'sim.type'))}
              </Typography.Text>
            </Descriptions.Item> */}
          </Descriptions>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}
