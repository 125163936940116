import { createSlice } from '@reduxjs/toolkit';
// import { LoginForm, LoginResponse, RegisterDataRequest, RegisterUser, RequestError } from "../../types/auth";
import { PayloadAction } from '@reduxjs/toolkit';
import { PackageType } from 'src/shared/interface/connection';
import { IPackageAssigned } from 'src/shared/interface/package_assigned';
// import {User} from "../../types/user";
import { v4 as uuidv4 } from 'uuid';

export interface GlobalState {
  token: string;
  role: string[];
  loading: boolean;
  showGoBack: boolean;
  refresh: boolean;
  contentModal: string;
  visibleModal: boolean;
  titleModal: string;
  contentModalWithIcon: string;
  visibleModalWithIcon: boolean;
  titleModalWithIcon: string;
  userName: string;
  queryStringPrevious: any;
  isAdvancedSearch: boolean;
  deviceId: string;
  requestId: string;
  openTabs: any[];
  activeKeyMenu: string;
  isOpenPayment: boolean;
  order?: IPackageAssigned;
  balance: number;
  masterData?: any;
  listPackageType: PackageType[];
}
const initialState: GlobalState = {
  token: '',
  role: [''],
  loading: false,
  showGoBack: true,
  visibleModal: false,
  refresh: false,
  contentModal: '',
  titleModal: '',
  userName: '',
  contentModalWithIcon: '',
  visibleModalWithIcon: false,
  titleModalWithIcon: '',
  queryStringPrevious: [],
  isAdvancedSearch: false,
  deviceId: uuidv4(),
  requestId: uuidv4(),
  openTabs: [
    {
      key: `dashboard`,
      label: `Dashboard`,
      children: '',
      closable: false,
    },
  ],
  activeKeyMenu: '',
  isOpenPayment: false,
  order: undefined,
  balance: 0,
  masterData: {},
  listPackageType: [],
};
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalState(state, action: PayloadAction<Partial<GlobalState>>) {
      Object.assign(state, action.payload);
    },
    reset(state: any) {
      Object.keys(state).forEach((stateKey) => {
        Object.keys(initialState).forEach((initStateKey) => {
          if (stateKey === initStateKey) {
            state[stateKey] = { ...initialState }[initStateKey];
          }
        });
      });
    },
  },
});

export const { actions: globalAction, reducer: globalReducer } = globalSlice;
export const { setGlobalState, reset } = globalSlice.actions;
