import { Form, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dataGet, formatDate } from 'src/utils';
import { type } from '@testing-library/user-event/dist/type';

export default function SimInfoTable({ dataSim }: any) {
  const columns: ColumnsType<any> = [
    {
      title: 'Gói đang sử dụng',
      dataIndex: 'package_name',
      key: 'package_name',
      width: 120,
      render: (_, record) =>
        !!record && (
          <Space size={'small'} wrap>
            {<Typography.Text key={0}>{dataGet(record, 'package_name', '')}</Typography.Text>}
          </Space>
        ),
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'start_time',
      key: 'start_time',
      width: 120,
      render: (_, record) =>
        !!record && (
          <Typography.Text>
            {dataGet(record, 'start_time') ? dataGet(record, 'start_time').split(' ')[0] : ''}
          </Typography.Text>
        ),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'end_time',
      key: 'end_time',
      width: 120,
      render: (_, record) =>
        !!record && (
          <Typography.Text>
            {dataGet(record, 'end_time') ? dataGet(record, 'end_time').split(' ')[0] : ''}
          </Typography.Text>
        ),
    },
    {
      title: 'Data sử dụng(Mbs)',
      dataIndex: 'total_traffic',
      key: 'total_traffic',
      width: 90,
      render: (_, record) =>
        !!record && (
          <Typography.Text>
            {dataGet(record, 'total_traffic') - dataGet(record, 'available_traffic') ?? 0}
          </Typography.Text>
        ),
    },
    {
      title: 'Data còn lại(Mbs)',
      dataIndex: 'available_traffic',
      key: 'available_traffic',
      width: 90,
      render: (_, record) =>
        !!record && <Typography.Text>{dataGet(record, 'available_traffic') ?? 0}</Typography.Text>,
    },
    {
      title: 'Ngày hết hạn kích hoạt',
      dataIndex: 'expired_date_wait',
      key: 'expired_date_wait',
      width: 120,
      render: (_, record) =>
        !!record && (
          <Typography.Text>
            {dataGet(record, 'expired_date_wait')
              ? dataGet(record, 'expired_date_wait').split(' ')[0]
              : ''}
          </Typography.Text>
        ),
    },
  ];

  return (
    <>
      <Table
        size={'small'}
        className='w-full'
        columns={columns}
        dataSource={dataSim.packageInfos.length ? dataSim.packageInfos : []}
        pagination={false}
        rowKey={(record) => {
          return record?.simType;
        }}
      />
    </>
  );
}
