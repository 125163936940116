import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import get from 'lodash/get';
import { REGEX_COMMON } from 'src/shared/common/regex';
import { message } from 'antd';

export const dataGet = (object: any, path: string, defaultValue: any = null) => {
  return get(object, path, defaultValue);
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const regex = /^84/;

  return phoneNumber.replace(regex, '0');
};

export const formatPhoneNumberV2 = (phoneNumber: string) => {
  if (!phoneNumber) {
    return;
  }
  // Remove 84
  phoneNumber = phoneNumber.replace(REGEX_COMMON.formatPhone, '0');

  if (phoneNumber.indexOf('0') !== 0) {
    phoneNumber = `0${phoneNumber}`;
  }

  // Remove any non-digit characters
  phoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the phoneNumber is valid
  if (phoneNumber.length < 10) {
    return phoneNumber;
  }

  // Format the phone number
  return phoneNumber.substring(0, 4) + phoneNumber.substring(4, 7) + phoneNumber.substring(7);
};
export const formatMoneyNoCurrency = (value: number) => {
  if (!+value) return '0';

  const number = `${value}`;
  const x = number.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/g;

  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }

  return x1 + x2;
};

export const formatMoney = (value: number, affix?: string) => {
  if (!+value) return 0 + ` ${affix ?? 'đ'}`;

  const number = `${Math.round(value)}`;
  const x = number.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }

  return x1 + x2 + `${affix ?? 'đ'}`;
};
export const parseQueryString = (queryURI: string) => {
  return JSON.parse(JSON.stringify(queryString.parse(queryURI)));
};
export const formatDateTime = (dateTime: string | Date) => {
  return dateTime ? moment(dateTime).format('DD/MM/YYYY HH:mm:ss') : 'N/A';
};
export const formatDate = (dateTime: string | Date) => {
  return dateTime ? moment(dateTime).format('DD/MM/YYYY') : 'N/A';
};
export const formatDateDash = (dateTime: string | Date) => {
  return dateTime ? moment(dateTime).format('DD-MM-YYYY') : '';
};
export const convertUTCDate = (date: Date | string) => {
  const tmp = new Date(date);
  tmp.setHours(14, 0, 0, 0);
  return tmp.toISOString();
};
export const convertMiddleDate = (date: Date | string) => {
  const tmp = new Date(date);
  tmp.setHours(12, 0, 0, 0);
  return tmp;
};
export const convertEndDate = (date: Date | string) => {
  const tmp = new Date(date);
  tmp.setHours(23, 59, 59, 59);
  return tmp;
};
export const removeMoneyFormat = (value: string) => {
  return value.replace(/,/g, '');
};
export const exportExcel = (url: string) => {
  const token = localStorage.getItem('token');
  return axios.get(url, {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  });
};
export const exportExcelPost = (url: string, data: any) => {
  const token = localStorage.getItem('token');
  return axios.post(process.env.REACT_APP_API_URL + url, data, {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token ?? '',
    },
  });
};
export const history = {
  navigate: null,
  location: null,
};
export const isImageUrl = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url?.toLowerCase());
};

export const parseJwt = (token: any) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const passwordRules = [
  {
    required: true,
    message: 'Thông tin này không được để trống',
  },
  {
    max: 8,
    message: 'Mật khẩu phải có 8 kí tự',
  },
  {
    min: 8,
    message: 'Mật khẩu phải có 8 kí tự',
  },
  {
    pattern: REGEX_COMMON.passWord,
    message: 'Mật khẩu bao gồm số, chữ và kí tự đặc biệt.',
  },
];

export const handleDownloadImage = async (imageUrl: string) => {
  if (imageUrl) {
    try {
      await fetch(imageUrl, {
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'image/png',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',
      }).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${fileName}`;
          alink.click();
        });
      });
    } catch (error) {
      console.log('res', error);
      window.open(imageUrl, '_blank');
    }
  } else {
    message.error('Đã xảy ra lỗi!');
  }
};
export const getDataQueryGraphql = (param?: any) => {
  return `{"query":"query Report_enterprise{
      report_enterprise {
        report_enterprise(offset: ${param?.offset ?? 0}, limit: ${param?.limit ?? 10},where:
          ${param?.where
      ? JSON.stringify(param?.where)
        .replaceAll(/{"/g, '{')
        .replaceAll(/":/g, ':')
        .replaceAll(/,"/g, ',')
      : null
    }, 
            order_by: {active_datetime: desc}) {
          sim_serial
          active_datetime
          phone_number
          sim_type
          current_package
          grant_packaage_date
          grant_package_staff
          changed_sim_datetime
          package_reg_date
          package_exp_date
          g_volume_usage
          balance
          enterprise_code
          enterprise_name
          party_role
          subscriber_name
          id_option
          id_number
          sub_status
          sim_status
        }
        report_enterprise_aggregate(where:
          ${param?.where
      ? JSON.stringify(param?.where)
        .replaceAll(/{"/g, '{')
        .replaceAll(/":/g, ':')
        .replaceAll(/,"/g, ',')
      : null
    }) {
          aggregate {
            count
          }
        }
      }
    }"}`;
};
export const getQueryExportGraphql = (dataSource: any, param: any) => {
  return `{"query":"query Report_enterprise{
      report_enterprise {
        report_enterprise(offset: 0, limit: ${dataGet(
    dataSource,
    'report_enterprise.report_enterprise_aggregate.aggregate.count',
    0,
  )},where:
          ${param.where
      ? JSON.stringify(param.where)
        .replaceAll(/{"/g, '{')
        .replaceAll(/":/g, ':')
        .replaceAll(/,"/g, ',')
      : null
    }, 
            order_by: {active_datetime: desc}) {
          sim_serial
          active_datetime
          phone_number
          sim_type
          current_package
          grant_packaage_date
          grant_package_staff
          changed_sim_datetime
          package_reg_date
          package_exp_date
          g_volume_usage
          balance
          enterprise_code
          enterprise_name
          party_role
          subscriber_name
          id_option
          id_number
          sub_status
          sim_status
        }
        report_enterprise_aggregate(where:
          ${JSON.stringify(param.where)
      .replaceAll(/{"/g, '{')
      .replaceAll(/":/g, ':')
      .replaceAll(/,"/g, ',')}) {
          aggregate {
            count
          }
        }
      }
    }"}`;
};


export const renderSubType = (masterData: any, subType?: string) => {
  if (subType) {
    const objectSubType = masterData?.subType?.find((item: any) => {
      return item?.description === subType
    })
    if (objectSubType) {
      return get(objectSubType, "name", subType)
    }
    else {
      return subType
    }
  }
  else {
    return subType;
  }
}
export function nonAccentVietnamese(str: string) {
  str = str?.toLowerCase();
  str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str?.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str?.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str?.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str?.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
}